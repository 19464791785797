const DefaultPath = 'files/semesters';

/**
 * Generic file loader method
 * @param dir Directory where file is located
 * @param file Name of the file
 * @returns {Promise<string>} Contents of the file
 */
export const loadFile = async ({dir, file}) => {
    const request = dir && dir.length > 0 ?
        `${DefaultPath}/${dir}/${file}` :
        `${DefaultPath}/${file}`;

    const response = await fetch(request);
    if(!response.ok) {
        throw new Error(`Response Error: ${response.status}: ${response.statusText}`);
    }
    return response.text();
}


/**
 * Parse lines
 * @param data Contents of the file
 * @param separator Typically either a comma or a tab
 * @returns {*} A 2D array with the contents of each line being an array of fields
 */
export const parseLines = (data, separator) => (
    data.trim()                         // Trim the data to fix any preceding or trailing white space
        .split(/\r?\n/g)                // Each course history entry is on its own line
        .map(line => line.trim())       // Trim the line to prevent extra entries
        .filter(line => line.length > 0)
        .filter(line => line.charAt(0) !== "<" && line.charAt(0) !== ">" && line.charAt(0) !== "#")   // Ignore comments
        .map(line => line.split(separator).map(entry => entry.trim())) // Lines are typically delimited by a comma or tab
)

/**
 * Get comment lines
 * @param data Contents of the file
 * @returns Array of lines containing comments
 */
export const getCommentLines = (data) => (
    data.trim()                         // Trim the data to fix any preceding or trailing white space
        .split(/\r?\n/g)                // Each course history entry is on its own line
        .map(line => line.trim())       // Trim the line to prevent extra entries
        .filter(line => line.charAt(0) === "<" || line.charAt(0) === ">" || line.charAt(0) === "#")   // Keep comments
)
