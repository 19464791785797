import PropTypes from 'prop-types';
import DisplayHeader from './DisplayHeader';
import Student from '../model/Student';

const Title = ({ student }) => (
    student && <DisplayHeader>Advising Plan for {student.getName()} ({student.displayID})</DisplayHeader>
);

Title.propTypes = {
    student: PropTypes.instanceOf(Student).isRequired
}

export default Title;
