import {Status, VERSION} from "./Dictionaries";

const courseToString = (course, student) => {
    const grade = course.noCredit && course.status === Status.successful ? 'NoCredit' : course.status;
    const line = [student.displayID, course.year, course.term, course.course.getJenzabarCode(), course.course.credits,
        grade, course.course.courseName]
        .concat(student.getAsArray().slice(1));
    if (course.elective) {
        line.push(course.elective.course.getJenzabarCode());
    } else {
        line.push(course.assignedElective);
    }
    return line.join('\t');
}

export const exportPlan = (history, student, commentLines) => {
    const statInfo = `STAT${VERSION}\t${student.catalogYear}\t${new Date().toISOString()}\t${student.jenzabarDate}`;
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(new Blob(
        [[statInfo].concat(history.courses.map(course => courseToString(course, student))).join('\n')
            .concat('\n' + commentLines.join('\n'))],
        { type: 'text/plain;charset=utf-8'}));
    a.setAttribute('download',
        `${student.lastname}_${student.firstname}_${student.displayID}_${student.major}.txt`);
    a.click();

    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
}