import {DragDropTypes, isRemoved, Status} from './Dictionaries';
import { uid } from '../utilities';

class HistoryCourse {
    id = "";
    year = 0;
    term = "";
    course = null;
    elective = null;
    noCredit = false;
    status = Status.unscheduled;

    constructor(data) {
        this.id = data.id || uid();                      // Unique Identifier for the course
        this.year = data.year || 0;                      // Year for the course in the history
        this.term = data.term || "";                     // Term for the course in the history
        this.course = data.course || null;               // Copy of the catalog course
        this.elective = data.elective || null;           // Link to the track course elective that this course satisfies
        this.trackCourse = data.trackCourse || null;     // Link to the track course (for unassigned electives)
        this.noCredit = data.noCredit === true;          // Grade replaced or waived course
        this.status = data.status || Status.unscheduled; // Course status
        this.assignedElective = data.assignedElective || ''; // Assigned elective (used for exported plans)
    }

    deepCopy() {
        return new HistoryCourse({
            id: this.id,
            year: this.year,
            term: this.term,
            course: this.course.deepCopy(),
            elective: this.elective,
            trackCourse: this.trackCourse,
            noCredit: this.noCredit,
            status: this.status
        });
    }

    equals(otherCourse) {
        return (
            otherCourse &&
            this.id === otherCourse.id &&
            this.year === otherCourse.year &&
            this.term === otherCourse.term &&
            ((!this.course && !otherCourse.course) || this.course.equals(otherCourse.course)) &&
            ((!this.elective && !otherCourse.elective) || (this.elective.equals(otherCourse.elective))) &&
            ((!this.trackCourse && !otherCourse.trackCourse) || (this.trackCourse.equals(otherCourse.trackCourse))) &&
            this.status === otherCourse.status
        );
    }

    canDragDrop() {
        return this.status === Status.unscheduled;
    }

    getDragDropType() {
        if(!this.canDragDrop()) {
            return undefined;
        }
        if(this.course.isQuarterCourse()) {
            if(this.course.isElective() || this.elective) {
                if(this.elective) {
                    return DragDropTypes.QuarterAssignedElective;
                } else {
                    return DragDropTypes.QuarterUnassignedElective;
                }
            }
            return DragDropTypes.QuarterCourse;
        }
        if(this.course.isSemesterCourse()) {
            if(this.course.isElective() || this.elective) {
                if(this.elective) {
                    return DragDropTypes.SemesterAssignedElective;
                } else {
                    return DragDropTypes.SemesterUnassignedElective;
                }
            }
            return DragDropTypes.SemesterCourse;
        }
    }

    /**
     * If moving an elective from Semesters to Quarters or vice versa, attempt to convert it to
     * the appropriate quarter/semester-based elective. This will be successful as long as the
     * elective has a corresponding elective in the alternative term type. E.g., EL-Sci has ELC-Sci.
     */
    convertToSufficient() {
        const sufficients = this.course.sufficients?.getAsArray();
        if(!sufficients || sufficients.length > 1 || sufficients.length === 0) {
            throw new Error(`Unable to determine equivalent for course ${this.getCourseName()}`);
        }
        this.course = sufficients[0].deepCopy();
        if(this.trackCourse) {
            this.trackCourse.course = sufficients[0].deepCopy();
        }
    }

    isQuarterCourse() {
        return this.course.isQuarterCourse()
    }

    isSemesterCourse() {
        return this.course.isSemesterCourse()
    }

    isElective() {
        return (this.status === Status.scheduled || this.status === Status.unscheduled || this.status === Status.missing) && (this.course.isElective() || this.elective);
    }

    isRemoved() {
        return isRemoved(this.year, this.term);
    }

    getAbbrCourseName() {
        return this.elective ? this.elective?.course?.courseName : this.course?.courseName;
    }

    getCourseName() {
        return (
            this.elective ?
                `${this.elective.course.courseName} - ${this.course.courseName}` :
                this.course?.courseName
        );
    }

    getCourseStructure() {
        return this.elective ? `(${this.course.getStructure()})` :
            this.isElective() ? '' : `(${this.course?.getStructure()})`;
    }

    getAbbrCourseCode() {
        return this.elective ?
            this.elective?.course?.getDisplayableCourseCode() :
            this.course?.getDisplayableCourseCode();
    }

    getCourseCode() {
        return (
            this.elective ?
                `${this.elective.course.getDisplayableCourseCode()} - ${this.course.getDisplayableCourseCode()}` :
                this.course?.getDisplayableCourseCode()
        );
    }

    getElectiveCode() {
        return (
            this.elective ?
                this.elective.course?.getDisplayableCourseCode() : this.course?.getDisplayableCourseCode()
        );
    }

    toString() {
        return `${this.getCourseCode()} - ${this.getCourseName()}`;
    }
}

export default HistoryCourse;
