import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDrag } from 'react-dnd';

import Popover from './Popover';
import CourseContextMenu from './CourseContextMenu';
import {DragDropTypes, Status} from '../model/Dictionaries'

const colorStyle = {
    successful: 'var(--successful-color)',
    unsuccessful: 'var(--unsuccessful-color)',
    wip: 'var(--wip-color)',
    scheduled: 'var(--scheduled-color)',
    unscheduled: 'var(--unscheduled-color)',
    missing: 'var(--unscheduled-color)',
};

export const Description = styled.span`
  display: none;
  width: min-content;
  @media print {
    display: inline-block;
    width: auto;
    color: black;
    background-color: white;
  }
`;

const StyledCoursePill = styled.span`
  color: black;
  display: inline-block;
  white-space: nowrap;
  width: fit-content;
  cursor: ${props => props.draggable ? 'grab' : 'default'};
  /* The resulting background color based on status. */
  background-color: ${props => props.unstyled ? undefined : colorStyle[props.status]};
  &:hover {
    box-shadow: ${props => props.draggable && !props.unstyled && '0 0 5px white !important'};
  }
  box-shadow: ${props => props.conflicted && !props.unstyled && 'inset 0 0 5px 2px red'};
  box-shadow: ${props => props.status === Status.missing && !props.unstyled && 'inset 0 0 2px 1px orange'};
  ${props => !props.unstyled && `
      margin: 0.1em 0.3em;
      padding-right: 0.3em;
      padding-left: 0.3em;
      padding-bottom: 0.1em;
      border-radius: 10rem;
  `}
  @media print {
    color: black;
    background-color: white;
    box-shadow: none;
    &:before {
      ${props => (props.status === Status.successful || props.status === Status.wip) && `content: '\u2713'`}
      ${props => props.status === Status.scheduled && `content: '\u2610'`}
      ${props => (props.status === Status.unsuccessful) && `color: red; content: '\u2718'`}
      ${props => (props.status === Status.missing) && `color: orange; content: '\u2718'`}
    };
  }
`;

Description.propTypes = {
    text: PropTypes.string,
}

StyledCoursePill.propTypes = {
    status: PropTypes.string,
    draggable: PropTypes.bool,
    title: PropTypes.string,
    unstyled: PropTypes.bool,
}

StyledCoursePill.defaultProps = {
    status: 'unscheduled',
    draggable: false,
    unstyled: false,
}

export const CoursePill = (props) => {

    const {
        title,
        description,
        location,
        menuOptions,
        visibilityOverride,
        dragRef,
        ...otherProps
    } = props;

    const [menuVisible, setMenuVisible] = useState(false);

    const PopoverParams = {
        Component: title ? Popover : React.Fragment,
        props: title ? {
            popoverContent: title,
            location: location,
            visibilityOverride: visibilityOverride && !menuVisible,
        } : {}
    };
    const ContextMenuParams = {
        Component: menuOptions ? CourseContextMenu : React.Fragment,
        props: menuOptions ? Object.assign({},
            {...menuOptions},
            {
                onMenuOpen: () => { setMenuVisible(true); },
                onMenuClose: () => { setMenuVisible(false); },
                visibilityOverride: visibilityOverride
            }) : {}
    };

    return (
        <PopoverParams.Component {...PopoverParams.props}>
            <ContextMenuParams.Component {...ContextMenuParams.props}>
                <StyledCoursePill ref={dragRef} {...otherProps} />
                <Description>{description}</Description>
            </ContextMenuParams.Component>
        </PopoverParams.Component>
    )
};

export const DraggableCoursePill = (props) => {

    const {
        id,
        type,
        ...otherProps
    } = props;

    const [{ isDragging }, drag ] = useDrag(() => ({
        type,
        item: { id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        })
    }));

    return (
        <CoursePill
            draggable = {!isDragging}
            visibilityOverride = {!isDragging}
            style = {{ opacity: isDragging ? 0 : 1 }}
            dragRef={drag}
            {...otherProps}
        />
    );
};
DraggableCoursePill.propTypes = {
    id: PropTypes.object,
    type: PropTypes.string,
};
DraggableCoursePill.defaultProps = {
    type: DragDropTypes.QuarterCourse,
}
