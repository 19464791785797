import { getValidMajorCode, MajorAndConcentrationByAbbreviation, FIRST_CATALOG_YEAR, MOST_RECENT_CATALOG_YEAR } from './Dictionaries';

/**
 * Represents a student.
 */
class Student {
    firstname = '';
    lastname = '';
    email = '';
    displayID = '';
    startYear = 0;
    catalogYear = 0;
    major = '';
    classStatus = '';
    expectedGradDate = null;
    jenzabarDate = '';
    planDate = '';
    minors = [];
    advisors = [];
    concentration = '';
    totalCredits = 0;

    constructor(data) {
        this.displayID = data.displayID || '';
        this.lastname = data.lastname || '';
        this.firstname = data.firstname || '';
        this.major = data.major || '';
        this.classStatus = data.classStatus || '';
        this.email = data.email || '';
        this.expectedGradDate = data.expectedGradDate || null;
        this.jenzabarDate = data.jenzabarDate || '';
        this.planDate = data.planDate || '';
        this.minors = data.minors || [];
        this.advisors = data.advisors || [];
        this.concentration = data.concentration || '';
        this.startYear = data.startYear || 0;
        this.catalogYear = data.catalogYear || (data.startYear ? Math.max(FIRST_CATALOG_YEAR, data.startYear) :
            MOST_RECENT_CATALOG_YEAR);
        this.totalCredits = data.totalCredits || 0;
    }

    getName() {
        return this.lastname + ', ' + this.firstname;
    }

    getTrackAbbreviation() {
        return `${this.getMajorCode()}${this.catalogYear}`;
    }

    canIncrementTrackYear() {
        return this.catalogYear < MOST_RECENT_CATALOG_YEAR;
    }

    canDecrementTrackYear() {
        return this.catalogYear > FIRST_CATALOG_YEAR;
    }

    setMajorAndConcentration(abbreviation) {
        const result = MajorAndConcentrationByAbbreviation[abbreviation];
        this.major = result[0];
        this.concentration = result[1];
    }

    getMinors() {
        return this.minors.filter(m => m.length > 0);
    }

    getMajorCode() {
        return getValidMajorCode(this.major, this.concentration);
    }

    getAdvisors() {
        return this.advisors.filter(m => m.length > 0 && !m.includes('**'));
    }

    getAsArray() {
        return [this.displayID, this.lastname, this.firstname, this.major, this.classStatus, this.email,
            (this.expectedGradDate || '')].concat(this.minors.concat(['', '', '', '']).slice(0,4))
            .concat(this.advisors.concat(['', '', '']).slice(0,3)).concat([this.concentration]);
    }
}

export default Student;
