export const array_unique = (array) => (
    [...new Set(array)]
);

export const array_equals = (a, b) => (
    JSON.stringify(a) === JSON.stringify(b)
);

export const range = (start, end) => (
    Array.from({ length: end - start + 1 }, (_, i) => i+start)
);

export const historyCourseCompare = (a, b) => (
    (a.unsatisfiedPrereqs || a.unsatisfiedCoreqs) === (b.unsatisfiedPrereqs || b.unsatisfiedCoreqs) ?
        a.getCourseCode().localeCompare(b.getCourseCode(), 'en', { sensitivity: 'base' }) :
        (a.unsatisfiedPrereqs || a.unsatisfiedCoreqs) ? 1 : -1
)

export const stringCompare = (a, b) => (
    a.localeCompare(b, 'en', { sensitivity: 'base' })
);

export const uid = () => (
    String(
        Date.now().toString(32) +
        Math.random().toString(16)
    ).replace(/\./g, '')
)

export const isNull = (value) => (
    typeof value === 'undefined' || value === null
);
