import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faTriangleExclamation, faCalendarXmark  } from '@fortawesome/free-solid-svg-icons';

import Popover from './Popover';

import { futureCourse, Status } from '../model/Dictionaries';
import HistoryCourse from '../model/HistoryCourse';
import { Description } from "./CoursePill";

const typeToIcon = (type) => (
    type === 'prereq' ? faTriangleExclamation :
    type === 'coreq' ? faCircleExclamation :
    type === 'offered' ? faCalendarXmark :
    type === 'creditLoad' ? faTriangleExclamation :
    type === 'extra' ? faTriangleExclamation :
    undefined
)
const severityToColor = (severity) => (
    severity === 'error' ? 'red' :
    severity === 'warning' ? 'yellow' :
    severity === 'good' ? 'green' :
    undefined
)
export const CourseErrorWarning = ({type, severity, description, title}) => {
    const WrapperComponent = title ? Popover : React.Fragment;
    const WrapperProps = title ? {
        popoverContent: title,
    }: {};
    return (
        <WrapperComponent {...WrapperProps}>
            <FontAwesomeIcon
                style={{
                    color: severityToColor(severity),
                }}
                icon={typeToIcon(type)}
            />
            <Description>{description}</Description>
        </WrapperComponent>
    );
};

const errorTypes = [
    {
        type: 'prereq',
        include: (courseEntry) => courseEntry.unsatisfiedPrereqs,
        buildTitle: (courseEntry) => `Missing prerequisite(s): ${courseEntry.unsatisfiedPrereqs.toString()}`,
    },
    {
        type: 'prereq',
        include: (courseEntry) => courseEntry.prereqForList?.length > 0,
        buildTitle: (courseEntry) => `Prerequisite for: ${courseEntry.prereqForList.map(entry => entry.getCourseCode()).join(', ')}`,
    },
    {
        type: 'coreq',
        include: (courseEntry) => courseEntry.unsatisfiedCoreqs,
        buildTitle: (courseEntry) => `Missing co-requisite(s): ${courseEntry.unsatisfiedCoreqs.toString()}`,
    },
    {
        type: 'coreq',
        include: (courseEntry) => courseEntry.coreqForList?.length > 0,
        buildTitle: (courseEntry) => `Co-requisite for: ${courseEntry.coreqForList.map(entry => entry.getCourseCode()).join(', ')}`,
    },
    {
        type: 'offered',
        include: (courseEntry) => (
            futureCourse(courseEntry.status) &&
            courseEntry.course.hasOfferings() &&
            !courseEntry.course.isOffered(courseEntry.year, courseEntry.term)
        ),
        buildTitle: (courseEntry) =>
            <>
                Not typically offered in selected term
                {courseEntry.course.getTrackOfferingStrings().length > 0 &&
                    <>
                        <br />Offered by Track:<br />
                        {
                            courseEntry.course.getTrackOfferingStrings().map(entry => (<React.Fragment key={entry}>&nbsp;&nbsp;{entry}<br/></React.Fragment>))
                        }
                    </>
                }
                {courseEntry.course.getHistoricOfferingStrings().length > 0 &&
                    <>
                        <br />Historic/Planned Offering:<br />
                        {
                            courseEntry.course.getHistoricOfferingStrings().map(entry => (<React.Fragment key={entry}>&nbsp;&nbsp;{entry}<br/></React.Fragment>))
                        }
                    </>
                }
            </>,
    }
];

const CourseWarningList = ({courseEntry, severity, includeTitle}) => (
    <>
        {errorTypes.map((errorType, index) => (
            errorType.include(courseEntry) &&
            <CourseErrorWarning
                key={`${courseEntry.id}_${index}`}
                type={errorType.type}
                severity={severity ? severity : errorType.type === 'offered' ? 'warning' : courseEntry.status === Status.wip || courseEntry.status === Status.successful ? 'warning' : 'error'}
                description={includeTitle ? errorType.buildTitle(courseEntry) : undefined}
                title={includeTitle ? errorType.buildTitle(courseEntry) : undefined}
            />
        ))}
    </>
);
CourseWarningList.propTypes = {
    courseEntry: PropTypes.instanceOf(HistoryCourse),
    severity: PropTypes.oneOf(['warning', 'error']),
    includeTitle: PropTypes.bool
}
CourseWarningList.defaultProps = {
    includeTitle: true,
}

export default CourseWarningList;
