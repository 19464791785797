import styled from 'styled-components';

const HelpButton = styled.a`
  text-decoration: none;
  padding: 3px 6px;
  color: #fff;
  background: var(--main-background-color);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  &:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(2px);
    width: calc(100% + 3px);
    height: calc(100% + 3px);
    animation: glowing 20s linear infinite;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
  }
  &:active {
    color: #aaa;
  }
`;

export default HelpButton;
