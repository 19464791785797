const outContainer = {
    margin: '5px auto',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '5px',
    borderColor: 'var(--msoe-red-color)',
};

const innerContainer = {
    margin: '20px',
};

const MainContent = (props) => (
    <div style={outContainer}>
        <div style={innerContainer}>
            {props.children}
        </div>
    </div>
);

export default MainContent;
