import { loadFile, parseLines } from './FileIO';
import { importElectives } from './ImportElectives';
import { Status } from "../model/Dictionaries";

/**
 * Reads files specifying the topical credit thresholds
 * @param major Major abbreviation
 * @param courseCatalog The populated course catalog
 * @returns {Promise<Map<any, any>>} A Map with <TopicalArea, threshold> mappings
 */
export const importCreditBuckets = async (major, courseCatalog) => {
    const data = await loadFile({ dir: major.toLowerCase(), file: 'Credits.csv'})
    if(data === undefined) {
        throw new Error('Unable to load data for credit buckets');
    }

    const dataLines = parseLines(data, ',');

    const topicalAreaMap = new Map();
    await Promise.all(dataLines.map(async (entry) => {
        const courses = await importElectives(major.toLowerCase(), entry[1], courseCatalog);
        topicalAreaMap.set(entry[1].slice(entry[1].indexOf('-') + 1),
            { required: parseInt(entry[0]), planned: 0, status: Status.unsuccessful, courses: courses});
    }));

    return topicalAreaMap;
}