import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';

import Popover from './Popover';
import CourseContextMenu from './CourseContextMenu';
import HistoryCourse from '../model/HistoryCourse';
import CatalogCourse from '../model/CatalogCourse';
import { Status } from '../model/Dictionaries';

const StyledAddCourse = styled.span`
  cursor: default;
  @media print {
    visibility: hidden;
  }
`;

const AddCourseButton = ({year, term, onAddSelect}) => {

    const [menuVisible, setMenuVisible] = useState(false);

    return (
        <Popover
            popoverContent="Click to add a course to this term"
            location="bottom"
            visibilityOverride={!menuVisible}
        >
            <CourseContextMenu
                year={year}
                term={term}
                leftClick={true}
                rightClick={false}
                placeholder='Select a course to add'
                onMenuOpen={() => { setMenuVisible(true); }}
                onMenuClose={() => { setMenuVisible(false); }}
                onMenuOptionSelect={(course) => {
                    if(course) {
                        if(course instanceof CatalogCourse) {
                            onAddSelect(new HistoryCourse({
                                year, term, course, status: Status.unscheduled,
                            }));
                        } else if(course instanceof HistoryCourse) {
                            onAddSelect(course);
                        }
                    }
                }}
            >
                <StyledAddCourse><FontAwesomeIcon icon={faCirclePlus} />&nbsp;Add Course</StyledAddCourse>
            </CourseContextMenu>
        </Popover>
    );
}
AddCourseButton.propTypes = {
    year: PropTypes.number.isRequired,
    term: PropTypes.string.isRequired,
    onAddSelect: PropTypes.func.isRequired,
};

export default AddCourseButton;
