import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useFilePicker } from 'use-file-picker';
import { MajorsByName } from '../model/Dictionaries';

import logoImage from '../images/logo.png';
import helpImage from '../images/help.png';
import ContextMenu from "./ContextMenu";
import { stringCompare } from "../utilities";



const HEADER_HEIGHT = 55;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  /*align-items: center;*/
  height: ${HEADER_HEIGHT}px;
  background-color: var(--msoe-red-color);
  position: sticky;
  z-index: 999998 !important;
  top: 0;
  @media print {
    visibility: hidden;
  }
`;

const ButtonNav = styled.div`
  display: flex;
  align-items: center;
`;

const LogoContainer = styled.div`
  height: ${HEADER_HEIGHT}px;
  margin: 0;
`;

const HELP_MARGIN = 10;
const HelpContainer = styled.div`
  margin: ${HELP_MARGIN}px;
  height: ${HEADER_HEIGHT - 2*HELP_MARGIN}px;
  width: ${HEADER_HEIGHT - 2*HELP_MARGIN}px;
`;

const NavButton = styled.button`
  margin: auto 5px;
  line-height: 25px;
`;

const NavButtonIcon = styled.span`
  margin-left: 5px;
`

const Image = styled.img`
  height: 100%;
`;

const PopupMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--main-background-color);
`;
const PopupMenuItemTitle = styled.span`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
`

const MenuItem = ({item}) => (
    <PopupMenuItem>
        <PopupMenuItemTitle>
            {item}
        </PopupMenuItemTitle>
    </PopupMenuItem>
);

const UploadButton = (props) => {
    const {
        loading,
        children,
        ...otherProps
    } = props;

    return (
        <NavButton
            disabled={loading}
            {...otherProps}
        >
            {children}
            <NavButtonIcon><FontAwesomeIcon icon={loading ? faSpinner : faPlus} spin={loading} /></NavButtonIcon>
        </NavButton>
    );
};

const HeaderNav = ({ transcriptLoaded, handleTrackSwitch, handleImportFile, handleExportPlan }) => {

    const [openFileSelector, { filesContent, errors, loading }] = useFilePicker({
        accept: ['.txt', '.pdf'],
        readAs: 'ArrayBuffer',
        multiple: false,
    });

    const majors = Object.keys(MajorsByName).filter(name => !name.includes('/')).sort(stringCompare).map(name => ({
        value: MajorsByName[name],
        label: <MenuItem item={name}></MenuItem>
    }));
    useEffect(() => {
        // Check for errors
        if(errors && errors.length > 0) {
            if(errors[0].readerError) {
                // Throw an error for a specific read error
                throw new Error(errors[0].readerError.message);
            } else {
                // Throw a generic error message
                throw new Error("Unable to load file.  Check the file contents and try again.");
            }
        }
        if(handleImportFile && filesContent && filesContent.length > 0) {
            handleImportFile(filesContent[0]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Header>
            <LogoContainer>
                <a href="https://www.msoe.edu"><Image src={logoImage} alt="STAT logo"/></a>
            </LogoContainer>
            <ButtonNav>
                <UploadButton
                    loading={loading}
                    onClick={() => openFileSelector()}
                >
                    Load Plan / Course History
                </UploadButton>
                {!transcriptLoaded &&
                    <NavButton onClick={() => handleImportFile()}>
                        Start Fresh
                    </NavButton>
                }
                {transcriptLoaded &&
                    <>
                        <ContextMenu
                            options={majors}
                            leftClick={true}
                            rightClick={false}
                            menuWidth={350}
                            placeholder='Select Major'
                            onMenuOptionSelect={(major) => {
                                if(major) {
                                    handleTrackSwitch(major)
                                }
                            }}
                        >
                            <NavButton>Switch Major</NavButton>
                        </ContextMenu>
                        <NavButton onClick={() => handleExportPlan()}>Export Plan</NavButton>
                    </>
                }
            </ButtonNav>
            <HelpContainer>
                <a href='https://csse.msoe.us/stat' target='_blank' rel='noreferrer'><Image src={helpImage} alt='Help'/></a>
            </HelpContainer>
        </Header>
    );
};

HeaderNav.propTypes = {
    transcriptLoaded: PropTypes.bool,
    handleImportFile: PropTypes.func.isRequired,
    handleExportPlan: PropTypes.func.isRequired,
};

HeaderNav.defaultProps = {
    transcriptLoaded: false,
};

export default HeaderNav;
