const headerStyle = {
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: '20px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
}

const DisplayHeader = (props) => (
    <div style={headerStyle}>{props.children}</div>
);

export default DisplayHeader;
