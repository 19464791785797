import { array_unique } from '../utilities';

import CatalogCourse from './CatalogCourse';
import HistoryCourse from './HistoryCourse';

/**
 * Models a curriculum track.
 */
class Track {
    major = '';
    name = '';
    url = '';
    courses = [];

    constructor(data) {
        this.major = data.major || '';
        this.name = data.name || '';
        this.url = data.url || '';
        this.courses = data.courses || [];
    }

    deepCopy() {
        return new Track({
            major: this.major,
            name: this.name,
            url: this.url,
            courses: this.courses.map((course) => course.deepCopy())
        });
    }

    getNumberOfSemesterYears() {
        return array_unique(this.courses.filter(trackCourse => trackCourse.term === 'S1')
            .map(trackCourse => trackCourse.year)).length;
    }

    getNumberOfYears() {
        return array_unique(this.courses.map(trackCourse => trackCourse.year)).length;
    }

    addCourse(course) {
        this.courses.push(course);
    }

    courseInTrack(courseEntry) {
        const courseToCheck =
            courseEntry instanceof CatalogCourse ? courseEntry :
            courseEntry instanceof HistoryCourse ? courseEntry.course : undefined;
        return courseEntry && this.courses.find((searchEntry) => (
                searchEntry.course.getCourseCode() === courseToCheck.getCourseCode()) ||
            (searchEntry.course.sufficients && searchEntry.course.sufficients.satisfies([courseToCheck]))
        ) !== undefined;
    };
}

export default Track;
