import {Status, successfulCourse, futureCourse, TRANSITION_YEAR, MOST_RECENT_CATALOG_YEAR} from './Dictionaries';
import HistoryCourse from './HistoryCourse';
import { getElectives } from './Electives';

// NOTE: This code mutates the history on each function call
//       A known issue with setting the history as a state object
//       causes problems with a stale history
//       Only ONE history can ever exist

const retrieveElectives = async (track, courseCatalog) => {
    const trackElectives = track.courses
        .filter(courseEntry => courseEntry.course.isElective());
    const electiveMap = new Map();
    await Promise.all(trackElectives.map(async (elective) => {
        const electiveCode = elective.course.getCourseCode();
        const electives = await getElectives(track.major, electiveCode, courseCatalog);
        electiveMap.set(electiveCode, electives);
    }));
    return { trackElectives, electiveMap };
}

const assignElectives = async (track, history, courseCatalog) => {

    // Deep copy the history
    // const newHistory = history.deepCopy();
    const newHistory = history;

    // Declaration of helper functions
    const courseEntryEquals = (a, b) => a.equals(b);
    const trackEntryEquals = (a, b) => a.equals(b);

    const getShortestMapEntryOfKeys = (map, keys) => (
        keys.reduce((shortest, currentValue) => (
            !map.has(shortest) ? currentValue :
                map.get(currentValue).length < map.get(shortest).length ?
                    currentValue : shortest
        ), null)
    );
    const getShortestMapEntryLengthOfKeys = (map, keys) => {
        const shortestEntry = getShortestMapEntryOfKeys(map, keys);
        return shortestEntry ? map.get(shortestEntry).length : 0;
    }
    const getShortestMapEntriesOfKeys = (map, keys) => {
        const shortestEntryLength = getShortestMapEntryLengthOfKeys(map, keys);
        return keys.filter(keyEntry => map.get(keyEntry).length === shortestEntryLength);
    };
    // const getShortestMapEntry = (map) => getShortestMapEntryOfKeys(map, [...map.keys()]);
    const getShortestMapEntries = (map) => getShortestMapEntriesOfKeys(map, [...map.keys()]);
    const removeFromMap = (map, value, equalsFunc) => {
        [...map.keys()].forEach((key) => {
            map.set(key, map.get(key).filter(searchEntry => {
                return !equalsFunc(searchEntry, value);
            }));
        });
    };

    // Load Electives
    const electiveResult = await retrieveElectives(track, courseCatalog);
    const trackElectivesUnfiltered = electiveResult.trackElectives;
    const electiveMap = electiveResult.electiveMap;

    // // Course history import - assign electives to courses from the exported plan
    // newHistory.courses.forEach((historyCourse) => {
    //     if(historyCourse.assignedElective) {
    //         const trackCourse = track.courses.find((trackCourse) => (
    //             trackCourse.getCourseCode() === historyCourse.assignedElective
    //         ));
    //         if(trackCourse) {
    //             newHistory.attachElective(historyCourse, trackCourse);
    //         }
    //     }
    // });

    // Filter unassigned track electives
    const trackElectives = trackElectivesUnfiltered.filter((trackCourse) => (
        newHistory.courses.find((historyCourse) => (historyCourse.elective && historyCourse.elective.equals(trackCourse))) === undefined
    ));
    const sortByElectivePossibilities = (a, b) => (
        electiveMap.get(a.course.getCourseCode()).length - electiveMap.get(b.course.getCourseCode()).length
    );

    // Elective decisions are based on 2 maps
    //   - coursesForElectives - maps each elective to a list of courses that satisfy that elective
    //   - electivesForCourses - maps each course to a list of electives that this course satisfies
    const validElectiveCourses = newHistory.courses.filter((courseEntry) => (
        !track.courseInTrack(courseEntry) && // Must be a course not already in the track
        !courseEntry.elective &&             // Must not have an elective already assigned
        (successfulCourse(courseEntry.status) || futureCourse(courseEntry.status))
    ));

    const coursesForElectives = new Map();
    trackElectives.forEach((elective) => {
        coursesForElectives.set(
            elective,
            validElectiveCourses.filter(validElectiveEntry =>
                electiveMap.get(elective.course.getCourseCode()).find((electiveEntry) => electiveEntry === validElectiveEntry.course.getCourseCode()))
        );
    });
    const electivesForCourses = new Map();
    validElectiveCourses.forEach((validElectiveEntry) => {
        electivesForCourses.set(
            validElectiveEntry,
            trackElectives.filter(elective =>
                electiveMap.get(elective.course.getCourseCode()).find((electiveEntry) => electiveEntry === validElectiveEntry.course.getCourseCode()))
        );
    });
    // console.log(coursesForElectives);
    // console.log(electivesForCourses);

    // Assign electives using the following heuristic
    //  1) find the electives with the shortest list of courses satisfying the elective
    //  2) find the course from the list of potential courses that satisfies the fewest electives
    //  3) assign the course to the elective
    //  4) remove the course from all potential electives
    //  5) repeat until the map of satisfying courses is empty
    while(coursesForElectives.size > 0) {

        // 1) find the electives with the shortest list of courses satisfying the elective
        const nextPotentialElectives = getShortestMapEntries(coursesForElectives);
        const sortedPotentials = nextPotentialElectives.sort(sortByElectivePossibilities);

        sortedPotentials.forEach((nextPotentialElective) => {

            // 2) find the course from the list of potential courses that satisfies the fewest electives
            const courseList = coursesForElectives.get(nextPotentialElective);
            const shortestCourse = getShortestMapEntryOfKeys(electivesForCourses, courseList);

            // 3) Assign the course to the elective
            if (shortestCourse) {
                newHistory.attachElective(shortestCourse, nextPotentialElective);
                electivesForCourses.delete(shortestCourse);
                removeFromMap(electivesForCourses, nextPotentialElective, trackEntryEquals);
                removeFromMap(coursesForElectives, shortestCourse, courseEntryEquals);
            }

            // 4) remove the course from all potential electives
            coursesForElectives.delete(nextPotentialElective);
        });
    }

    return newHistory;
}

const addTrackCoursesToHistory = async(track, history) => {
    // Don't add track-related courses if there are any scheduled courses
    //if (history.courses.find(entry => entry.status === Status.scheduled)) {
    //    return history;
    //}
    // const newHistory = history.deepCopy();
    const newHistory = history;
    const numberOfSemesterYears = track.getNumberOfSemesterYears();
    const numberOfYears = track.getNumberOfYears();
    const offset = numberOfSemesterYears < 4 ? numberOfSemesterYears + (TRANSITION_YEAR - 1 - numberOfYears) : MOST_RECENT_CATALOG_YEAR - 1;
    const successfulCourses = newHistory.getPotentialSufficientCourse();
    track.courses.forEach((courseEntry) => {
        const satisfiesSufficient = (course, successfulCourses) => (
            course.sufficients && course.sufficients.satisfies(successfulCourses)
        );
        const course = history.findMostRecentCourseEntry(courseEntry.course);
        if (!courseEntry.course.isElective() &&  // Ignore electives
            !(course && (successfulCourses.includes(course.course) || course.status === Status.missing)) &&
            !satisfiesSufficient(courseEntry.course, successfulCourses)) {
            newHistory.addCourse(
                new HistoryCourse({
                    year: courseEntry.year + offset,
                    term: courseEntry.term,
                    course: courseEntry.course,
                    status: Status.unscheduled,
                })
            );
        }
    });
    return newHistory;
}

const matchTrackElectivesFromHistory = async(track, history, courseCatalog) => {
    // Don't add track-related electives if there are any scheduled courses
    //if (history.courses.find(entry => entry.status === Status.scheduled)) {
    //    return history;
    //}
    // const newHistory = history.deepCopy();
    const newHistory = history;

    const unassignedTrackElectives = track.courses
        .filter((trackCourse) => trackCourse.course.isElective())
        .filter((trackCourse) =>
            history.courses.find((historyCourse) =>
                historyCourse.trackCourse && historyCourse.trackCourse.equals(trackCourse)) === undefined);

    // Course history import - assign electives to courses from the exported plan
    newHistory.courses.filter(historyCourse => historyCourse.assignedElective).forEach((historyCourse) => {
        const trackCourseIdx = unassignedTrackElectives.findIndex((trackCourse) => (
            trackCourse.getCourseCode() === historyCourse.assignedElective ||
            (trackCourse.course.sufficients && trackCourse.course.sufficients.satisfies([
                courseCatalog.findCourse(historyCourse.assignedElective.replace(' ', '-'))
            ]))
    ));
        if(trackCourseIdx >= 0) {
            newHistory.attachElective(historyCourse, unassignedTrackElectives[trackCourseIdx]);
            unassignedTrackElectives.splice(trackCourseIdx, 1);
        }
    });

    // Course history import - attach unassigned electives to the track
    newHistory.courses.filter(historyCourse => (historyCourse.isElective() && !historyCourse.trackCourse))
        .forEach((historyCourse) => {
            // Search for exact matching elective course codes
            const trackCourseIdx = unassignedTrackElectives.findIndex((trackCourse) => (
                trackCourse.getCourseCode() === historyCourse.getCourseCode()
            ));
            if(trackCourseIdx >= 0) {
                newHistory.assignTrackCourse(historyCourse, unassignedTrackElectives[trackCourseIdx]);
                unassignedTrackElectives.splice(trackCourseIdx, 1);
                return;
            }
            // Search for sufficient courses for elective course
            const sufficientTrackCourseIdx = unassignedTrackElectives.findIndex((trackCourse) => (
                trackCourse.course.sufficients && trackCourse.course.sufficients.satisfies([historyCourse.course])
            ));
            if(sufficientTrackCourseIdx >= 0) {
                newHistory.covertAndAssignTrackCourse(historyCourse, unassignedTrackElectives[sufficientTrackCourseIdx]);
                unassignedTrackElectives.splice(sufficientTrackCourseIdx, 1);
            }
        });
    return newHistory;
}

const addTrackUnassignedElectivesToHistory = async(track, history) => {
    // Don't add track-related electives if there are any scheduled courses
    //if (history.courses.find(entry => entry.status === Status.scheduled)) {
    //    return history;
    //}
    // const newHistory = history.deepCopy();
    const newHistory = history;
    const numberOfSemesterYears = track.getNumberOfSemesterYears();
    const numberOfYears = track.getNumberOfYears();
    const offset = numberOfSemesterYears < 4 ? numberOfSemesterYears + (TRANSITION_YEAR - 1 - numberOfYears) : MOST_RECENT_CATALOG_YEAR - 1;

    // Find all unassigned track electives
    const unassignedTrackElectives = track.courses
        .filter((trackCourse) => trackCourse.course.isElective())
        .filter((trackCourse) =>
            history.courses.find((historyCourse) =>
                historyCourse.trackCourse && historyCourse.trackCourse.equals(trackCourse)) === undefined);

    // Add the electives to the history
    unassignedTrackElectives.forEach((courseEntry) => {
        newHistory.addCourse(
            new HistoryCourse({
                year: courseEntry.year + offset,
                term: courseEntry.term,
                course: courseEntry.course,
                status: Status.unscheduled,
                trackCourse: courseEntry,
            })
        );
    });

    // Consistency check, make sure all electives are accounted for in the course history
    const testEmptyElectives = newHistory.getEmptyElectives();
    if(testEmptyElectives.length > 0) {
        throw new Error(`Course history contains electives that are not part of the track: ${testEmptyElectives.map(entry => entry.getCourseCode()).join(', ')}`);
    }
    return newHistory;
}

const moveUnscheduledToNextPossibleTerm = async(track, history) => {
    // const newHistory = history.deepCopy();
    const newHistory = history;
    const targetYearTerm = newHistory.findFirstModifiableTerm();
    if(targetYearTerm) {
        newHistory.courses.forEach((courseEntry) => {
            if (courseEntry.status === Status.unscheduled &&
                !newHistory.canModifyCoursesInTerm(courseEntry.year, courseEntry.term)) {
                newHistory.moveCourse(courseEntry, targetYearTerm.year, targetYearTerm.term);
            }
        });
    }
    return newHistory;
}

/**
 * Creates an initial Individual Transition Plan schedule based on a specified track and course history
 * @param track Track of interest
 * @param history Initial course history
 * @param courseCatalog Course catalog
 * @param planDate Used to determine if it will attempt to assign courses to electives
 * @returns {*} A course history with all required courses included
 */
export const buildCourseSchedule = async(track, history, courseCatalog, planDate) => {

    // Add courses from the track that haven't been taken yet
    const trackMergedHistory = await addTrackCoursesToHistory(track, history);

    // For history import, match up assigned and unassigned electives
    const electiveMatchedHistory = await matchTrackElectivesFromHistory(track, trackMergedHistory, courseCatalog);

    // Assign electives
    const electiveAssignedHistory = planDate ? electiveMatchedHistory : await assignElectives(track, electiveMatchedHistory, courseCatalog);

    // Add unassigned electives from track
    const fullHistoryForTrack = await addTrackUnassignedElectivesToHistory(track, electiveAssignedHistory);

    // Move unscheduled courses to the first possible term
    return await moveUnscheduledToNextPossibleTerm(track, fullHistoryForTrack);
}
