/**
 * Manages all courses in the quarter-based and semester-based undergraduate course catalogs.
 * The course catalog has information on each course related to:
 *  * Pre/co-requisites
 *  * Sufficient replacement course(s)
 *  * When a course is likely to be offered
 */
class CourseCatalog {
    courses = null;

    constructor() {
        this.courses = new Map();
    }

    /**
     * Adds a course to the course catalog
     * @param course The course to be added
     */
    addCourse(course) {
        this.courses.set(course.getCourseCode(), course);
    }

    /**
     * Returns the course with the specified course code
     * @param code The course code
     * @returns {CatalogCourse} The course in the catalog, if it exists
     */
    findCourse(code) {
        return this.courses.get(code);
    }

    /**
     * Returns an array of all the course codes
     * @returns {*[]} an array of all the course codes
     */
    getAsCourseCodeArray() {
        return [...this.courses.keys()];
    }

    /**
     * Returns an array of all the courses
     * @returns {*[]} an array of all the courses
     */
    getAsCourseArray() {
        return [...this.courses.values()];
    }
}

export default CourseCatalog;
