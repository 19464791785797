import { importElectives } from '../import/ImportElectives';

// Electives by major
//   Map<major, Map<string, ElectiveList>
//     Maps major code to map of electives by elective code (e.g. EL-XXX or ELC-YYY)
//
//   ElectiveList = Array<string>
//     Array of course codes
const electivesByMajor = new Map();

const cacheHasElectives = (major, electiveCode) => (
    electivesByMajor.has(major) && electivesByMajor.get(major).has(electiveCode)
);
const getElectivesFromCache = (major, electiveCode) => (
    electivesByMajor.has(major) && electivesByMajor.get(major).get(electiveCode)
);
const setElectivesInCache = (major, electiveCode, electives) => {
    if(!electivesByMajor.has(major)) {
        electivesByMajor.set(major, new Map());
    }
    electivesByMajor.get(major).set(electiveCode, electives);
}

/**
 * Forces all minor electives to be cached.
 * @param electiveCode
 * @returns {false|*}
 */
export const getMinorElectives = (electiveCode) => (
    electivesByMajor.has('minors') && electivesByMajor.get('minors').get(electiveCode)
)

/**
 * Returns an ElectiveList of courses meeting the requirements for an elective for a specified major
 * @param major Major of interest
 * @param electiveCode Elective code of interest
 * @param courseCatalog Course catalog
 * @returns {*}  an ElectiveList of courses meeting the requirements for an elective for a specified major
 */
export const getElectives = async (major, electiveCode, courseCatalog) => {
    // Check if electives are in the cache
    if(cacheHasElectives(major, electiveCode)) {
        return getElectivesFromCache(major, electiveCode);
    }

    // Import missing electives
    const importedElectives = await importElectives(major, electiveCode, courseCatalog);

    // Set the imported electives in the cache and return the values
    setElectivesInCache(major, electiveCode, importedElectives);
    return importedElectives;
}
