import {TermByCode} from './Dictionaries';
import {isNull, range, stringCompare} from '../utilities';

export const EquivalenceSetOp = {
    AND: "AND",
    OR: "OR",
};

export class EquivalenceSet {
    op = '';
    elements = [];
    parent = null;

    constructor(data) {
        this.op = data?.op || '';
        this.elements = data?.elements || [];
        this.parent = null;
    }

    setOperation(op) {
        if(this.op.length === 0) {
            this.op = op;
        }
        if(this.op !== op) {
            throw new Error(`Error in equivalence set expected op '${this.op}' and received '${op}'`);
        }
        this.op = op;
    }

    satisfies(elementSet, alternative) {
        const satisfied = this.elements.map((element) => (
            element instanceof EquivalenceSet ? element.satisfies(elementSet) :
            elementSet.find(searchElement => searchElement.equals(element)) !== undefined ||
                (alternative && element[alternative]?.satisfies(elementSet))
        ));
        return (
            this.op === EquivalenceSetOp.AND ? satisfied.length > 0 && satisfied.every(sat => sat) :
            this.op === EquivalenceSetOp.OR ? satisfied.length > 0 && satisfied.some(sat => sat) :
            false
        );
    }

    unsatisfiesSet(elementSet, alternative) {
        const unsatisfiedSet = this.elements && this.elements.length > 0 ? this.elements.map((element) => (
            element instanceof EquivalenceSet ? element.unsatisfiesSet(elementSet, alternative) :
            elementSet.find(searchElement => searchElement.equals(element)) === undefined &&
                !(alternative && element[alternative]?.satisfies(elementSet, alternative)) ? element : undefined
        )) : [];
        if(unsatisfiedSet && unsatisfiedSet.length > 0) {
            if (this.op === EquivalenceSetOp.AND) {
                return unsatisfiedSet.some(sat => sat !== undefined) ?
                    new EquivalenceSet({
                        op: this.op,
                        elements: unsatisfiedSet.filter(element => element !== undefined)
                    }) : undefined;
            }
            if (this.op === EquivalenceSetOp.OR) {
                return unsatisfiedSet.every(sat => sat !== undefined) ?
                    new EquivalenceSet({
                        op: this.op,
                        elements: unsatisfiedSet.filter(element => element !== undefined)
                    }) : undefined;
            }
        }
        return undefined;
    }

    satisfiesSet(elementSet, alternative) {
        const satisfiedSet = this.elements.map((element) => (
            element instanceof EquivalenceSet ? element.satisfiesSet(elementSet, alternative) :
                elementSet.find(searchElement => (searchElement && searchElement.equals(element))) ||
                (alternative && element[alternative]?.satisfiesSet(elementSet, alternative))
        ));
        return new EquivalenceSet({ op: this.op, elements: satisfiedSet.filter(element => element !== undefined) });
    }

    toString() {
        const formatElementSet = (elementSet) => elementSet.elements.length > 1 ? `( ${elementSet.toString()} )` : elementSet.elements[0].toString();
        return this.elements
            .map(element => element instanceof EquivalenceSet ? formatElementSet(element) : element.getDisplayableCourseCode())
            .join(` ${this.op} `);
    }

    getAsArray() {
        return this.elements.flatMap((element) => element instanceof EquivalenceSet ? element.getAsArray() : element);
    }
}

/**
 * Represents a course in the MSOE undergraduate catalog
 */
class CatalogCourse {
    _prefix = "";
    _number = "";
    courseName = "";
    lectureHours = 0;
    labHours = 0;
    credits = 0;
    repeatable = false;
    prereqs = null;
    coreqs = null;
    sufficients = null;
    trackOffered = [];
    historyOffered = null;

    constructor(data) {
        this._prefix = data.prefix || "";
        this._number = data.number || "";
        this.courseName = data.courseName || "";
        this.lectureHours = data.lectureHours || 0;
        this.labHours = data.labHours || 0;
        this.credits = data.credits || 0;
        this.repeatable = data.repeatable === true;
        this.prereqs = data.prereqs || null;
        this.coreqs = data.coreqs || null;
        this.sufficients = data.sufficients || null;
        this.trackOffered = data.trackOffered || [];
        this.historyOffered = data.historyOffered || null;
    }

    deepCopy() {
        return new CatalogCourse({
            prefix: this._prefix,
            number: this._number,
            courseName: this.courseName,
            lectureHours: this.lectureHours,
            labHours: this.labHours,
            credits: this.credits,
            repeatable: this.repeatable,
            prereqs: this.prereqs,
            coreqs: this.coreqs,
            sufficients: this.sufficients,
            trackOffered: this.trackOffered,
            historyOffered: this.historyOffered
        });
    }

    // We want a unique course in two situations:
    // 1. The credits differ
    // 2. The course is repeatable and the course name is unique
    deepCopyWhenDifferingCreditsOrName(name, credits) {
        return credits !== this.credits || (this.repeatable && name !== this.courseName) ?
            new CatalogCourse({
                prefix: this._prefix,
                number: this._number,
                courseName: name,
                lectureHours: this.lectureHours,
                labHours: this.labHours,
                credits: credits,
                repeatable: this.repeatable,
                prereqs: this.prereqs,
                coreqs: this.coreqs,
                sufficients: this.sufficients,
                trackOffered: this.trackOffered,
                historyOffered: this.historyOffered
            }) : this;
    }

    deepCopyWhenDifferingName(name) {
        return (this.repeatable && name !== this.courseName) ?
            new CatalogCourse({
            prefix: this._prefix,
            number: this._number,
            courseName: name,
            lectureHours: this.lectureHours,
            labHours: this.labHours,
            credits: this.credits,
            repeatable: this.repeatable,
            prereqs: this.prereqs,
            coreqs: this.coreqs,
            sufficients: this.sufficients,
            trackOffered: this.trackOffered,
            historyOffered: this.historyOffered
        }) : this;
    }

    equals(otherCourse) {
        return (
            otherCourse &&
            this._prefix === otherCourse._prefix &&
            this._number === otherCourse._number &&
            this.courseName === otherCourse.courseName &&
            this.lectureHours === otherCourse.lectureHours &&
            this.labHours === otherCourse.labHours &&
            //this.credits === otherCourse.credits &&
            this.repeatable === otherCourse.repeatable
        );
    }

    getCourseCode() {
        return this._prefix + '-' + this._number;
    }

    /**
     * Represents the course code as a string that matches the format produced by the Jenzabar export.
     * The Jenzabar format has five characters for the prefix and five for the course number
     * @returns {string} The course code in Jenzabar's format
     */
    getJenzabarCode() {
        return this._prefix.padEnd(5) + this._number.padEnd(5);
    }

    /**
     * True if the course represents an elective
     * @returns {boolean} true if the course represents an elective
     */
    isElective() {
        return (this._prefix.length === 3 && this._prefix === 'ELC') ||
            (this._prefix.length === 2 && this._prefix === 'EL')
    }

    isQuarterCourse() {
        return this._prefix.length === 2;
    }

    isSemesterCourse() {
        return this._prefix.length === 3;
    }

    /**
     * Applies a quarter-to-semester scaling factor if the course is a quarter-based course
     * @returns {number} Number of semester-credits for the course
     */
    getSemesterCredits() {
        return this.credits * (this._prefix.length === 2 ? 2.0 / 3 : 1);
    }

    /**
     * Returns true if there is information about when this course appears on curriculum track
     * @returns {boolean} true if the course appears on a curriculum track
     */
    hasTrackOfferings() {
        return !isNull(this.trackOffered) && this.trackOffered.length > 0;
    }

    /**
     * Returns true if the course appears on a curriculum track in the given term
     * @param term Quarter or semester of interest
     * @returns {boolean} true if the course appears on a curriculum track in the given term
     */
    isOfferedByTrack(term) {
        return (
            this.trackOffered &&
            this.trackOffered.length > 0 &&
            this.trackOffered.includes(term)
        );
    }

    /**
     * Returns true if information is available on when this course was offered or plans to be offered.
     * @returns {boolean} true if information is available on when this course was offered or plans to be offered.
     */
    hasHistoryOfferings() {
        return !isNull(this.historyOffered);
    }

    /**
     * Returns true if the course is offered in the specified year and term or in the previous "length" years in the past.
     * @param year Year of interest
     * @param term quarter/semester of interest
     * @param length Number of years to look back for historical data
     * @returns {null|boolean} true if the course is offered in the specified year and term or in the previous "length" years in the past.
     */
    isOfferedByHistory(year, term, length) {
        return (
            this.historyOffered &&
            range(year - length, year)  // Check the historic offerings from one year back
                .some(year => (
                    this.historyOffered.has(year) &&               // Offering history must have the year
                    this.historyOffered.get(year).includes(term))  // Offering history must have the term
                )
        );
    }

    /**
     * Returns true if there is at least track or historical/planned information on when this course is offered.
     * @returns {boolean} true if there is at least track or historical/planned information on when this course is offered.
     */
    hasOfferings() {
        return this.hasTrackOfferings() || this.hasHistoryOfferings();
    }

    /**
     * Returns true if the course is offered by a track in the specified term or in the specified year and term or in the previous year.
     * @param year Year of interest
     * @param term quarter/semester of interest
     * @param track curriculum track to check if offered
     * @returns {boolean|null} true if the course is likely to be offered this year and term.
     */
    isOffered(year, term, track) {
        return (
            this.isOfferedByTrack(term, track) ||        // T0DO: the isOfferedByTrack() method only takes in a term (no track)
            this.isOfferedByHistory(year, term, 1)
        );
    }

    /**
     * Returns an array of when the course is offered on a curriculum track
     * @returns {*[]} an array of when the course is offered on a curriculum track
     */
    getTrackOfferingStrings() {
        if(!this.trackOffered) {
            return [];
        }
        return this.trackOffered.sort(stringCompare).map(entry => TermByCode[entry]);
    }

    /**
     * Returns when this course was historically offered and is planned to be offered.
     * @returns {any[]|*[]} Returns when this course was historically offered and is planned to be offered.
     * T0DO: Improve
     */
    getHistoricOfferingStrings() {
        if(!this.historyOffered) {
            return [];
        }
        const termStrings = new Map();
        [...this.historyOffered.keys()].forEach(year => {
            this.historyOffered.get(year).forEach(term => {
                termStrings.set(`${TermByCode[term]} ${year-1}-${year}`, 1);
            });
        });
        return [...termStrings.keys()].sort(stringCompare);
    }

    /**
     * Returns the course structures as lecture-lab-credits
     * @returns {string} course structure
     */
    getStructure() {
        return this.credits === -1 ? 'X-X-Variable' : `${this.lectureHours}-${this.labHours}-${this.credits}`;
    }

    /**
     * The course code for the course with a space between the prefix and course number
     * @returns {string} A displayable course code
     */
    getDisplayableCourseCode() {
        return this.getCourseCode().replace('-', ' ');
    }

    /**
     * The displayable course code followed by the course name
     * @returns {string} The displayable course code followed by the course name
     */
    toString() {
        return `${this.getDisplayableCourseCode()} - ${this.courseName}`;
    }
}

export default CatalogCourse;
