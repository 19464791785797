import { loadFile, parseLines } from './FileIO';
import { array_unique } from '../utilities';

// Elective file cache
//   Map<{dir: major, file: electiveCode.csv}, string>
//     Map file identifier to contents
const electiveFileCache = new Map();
const cacheHasFile = (file) => (
    electiveFileCache.has(JSON.stringify(file))
);
const getFileContentsFromCache = (file) => (
    electiveFileCache.get(JSON.stringify(file))
);
const setFileContentsInCache = (file, data) => {
    electiveFileCache.set(JSON.stringify(file), data)
}

const isFile = (string) => (
    string.endsWith('.csv')
);
const getMajorAndCodeFromPath = (string) => {
    const match = string.match(/([^/]+)\/([^/]+)\.csv/);
    if(match) {
        return { major: match[1], electiveCode: match[2] }
    }
    return undefined;
}
const getElectiveFileData = async (major, electiveCode) => {
    const file = {dir: major, file:`${electiveCode}.csv`}
    if(cacheHasFile(file)) {
        return getFileContentsFromCache(file);
    }
    const data = await loadFile(file);
    if(data === undefined) {
        throw new Error(`Unable to load data for electives: ${electiveCode}`);
    }
    setFileContentsInCache(file, data);
    return data;
}

const isRegEx = (string) => (
    ['.' , '+' , '*' , '?' , '^' , '$' , '(' , ')' , '[' , ']' , '{' , '}' , '|' , '\\']
        .some((entry) => string.includes(entry))
);

const importElectivesFromFile = async (major, electiveCode, seen, courseCodes) => {

    // console.log(major + " " + electiveCode + " " + seen);
    const nowSeen = seen.concat({major, electiveCode});

    const data = await getElectiveFileData(major, electiveCode);
    const dataLines = parseLines(data, ',');
    const electiveEntries = dataLines.map((entry) => (
        entry[0]
    ));

    // Determine which entries are for files
    const files = electiveEntries
        .filter((entry) => isFile(entry))
        .map((entry) => getMajorAndCodeFromPath(entry))
        .filter((entry) => (entry && entry.major && entry.electiveCode &&
            !seen.find((searchEntry) => searchEntry.major === entry.major && searchEntry.electiveCode === entry.electiveCode)));
    const nonFileEntries = electiveEntries.filter((entry) => !isFile(entry));

    // Retrieve the entries for those entries
    const entriesFromFiles = array_unique((await Promise.all(files.map((entry) => (
        importElectivesFromFile(entry.major, entry.electiveCode, nowSeen, courseCodes)
    )))).flat());

    // Add courses based on course code or regular expression
    const allElectiveEntries = nonFileEntries.concat(entriesFromFiles);
    const positiveEntries = allElectiveEntries.filter((entry) => entry.charAt(0) !== '-');
    const allRegExEntries = positiveEntries.flatMap((entry) => (
        courseCodes.filter((courseEntry) => isRegEx(entry) ? courseEntry.match(`^${entry}`) : courseEntry === entry)
    ));

    // Filter courses based on negative course codes and negative regular expressions
    const negativeEntries = allElectiveEntries.filter((entry) => entry.charAt(0) === '-').map((entry) => entry.substring(1));
    const filteredNegEntries =  negativeEntries.length > 0 ? allRegExEntries.filter((entry) => (
        !negativeEntries.some((searchEntry) => isRegEx(searchEntry) ? entry.match(`^${searchEntry}`) : entry === searchEntry))
    ) : allRegExEntries;

    // Return only unique entries
    return array_unique(filteredNegEntries);
}

/**
 * Reads the list of courses that meet the requirements for an specific elective code.
 *
 * Note: This list may differ from one major to another even with the same elective code.
 * @param major Major abbreviation
 * @param electiveCode The elective code
 * @param courseCatalog The populated course catalog
 * @returns {Promise<*>} The list of courses that meet the elective requirement
 */
export const importElectives = async (major, electiveCode, courseCatalog) => {
    return importElectivesFromFile(major, electiveCode, [], courseCatalog.getAsCourseCodeArray());
}
