import styled from "styled-components";
import {ProgramDirector} from "../model/Dictionaries";

const Consent = styled.div`
  display: none;
  width: min-content;
  ol {
    list-style-type: decimal;
  }
  ul {
    list-style-type: none;
    padding: 0;
    border-radius: 3px;
  }
  ul > li {
    display: flex;
    justify-content: flex-end;
    padding: .5em;
  }
  @media print {
      display: block;
      width: auto;
    }
`;

const Name = styled.label`
  padding: .5em 1em .5em 0;
  text-align: right;
  flex: 5;
`;

const Date = styled.label`
  padding: .5em 1em .5em 0;
  text-align: right;
  flex: 1;
`;

const NameSign = styled.input`
  flex: 6;
`;

const DateFill = styled.input`
  flex: 4;
`;

const Line = ({name}) => (
    <li id={name}>
        <Name>{name}:</Name>
        <NameSign />
        <Date>Date:</Date>
        <DateFill />
    </li>
)

export const Signatures = ({student}) => {
    const progDir = `Program Director (${ProgramDirector[student.major]})`;
    const advisor = `Advisor (${student.advisors[0]})`;
    const studentName = `Student (${student.firstname} ${student.lastname})`;
    return (
        <Consent>
            <p>This curriculum replaces the one provided to you in the MSOE Undergraduate Academic Catalog
                upon entry to the University. All signatures below indicate confirmation of the agreed-upon
                graduation requirements. Additional details are provided in the Undergraduate Semester Conversion
                Guide.</p>
            <ul>
                <Line name={studentName} />
                <Line name={advisor} />
                <Line name={progDir} />
            </ul>
        </Consent>
    );
}

export default Signatures;