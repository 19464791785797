import { loadFile, parseLines } from './FileIO';
import CatalogCourse from '../model/CatalogCourse';
import { MinorByCode } from "../model/Dictionaries";
import { array_unique } from "../utilities";
import { getElectives } from "../model/Electives";

const importMinor = async (minor, catalog) => {
    const data = await loadFile({dir: 'minors', file:`${minor}.csv`});
    if(data === undefined) {
        throw new Error(`Unable to load data for ${MinorByCode[minor]} minor`);
    }

    const dataLines = parseLines(data, ',');

    if(dataLines.length <= 0) {
        throw new Error(`No courses specified for ${MinorByCode[minor]} minor`);
    }

    const courses = [];
    dataLines.forEach((courseEntry) => {
        // Search for the course in the catalog, if not found build a placeholder
        const courseCode = courseEntry[0];
        const course = catalog.findCourse(courseCode) || new CatalogCourse({
            'prefix': courseCode.slice(0, courseCode.indexOf('-')),
            'number': courseCode.slice(courseCode.indexOf('-') + 1),
        });
        courses.push(course);
    });
    return [minor, courses];
}

/**
 * Uses the list of minors defined in model/Dictionaries.MinorByCode to populate a Map of
 * the requirements for each minor.
 * @param catalog The populated course catalog
 * @returns {Promise<Map<any, any>>} A Map of all the minor requirements
 */
export const importMinors = async (catalog) => {
    const minorCourses = await Promise.all(Object.keys(MinorByCode).map(minor => (importMinor(minor, catalog))));
    await Promise.all(array_unique(minorCourses.map(entry => entry[1])
        .map(courses => courses.filter(course => course.isElective()))
        .flat()).map(elective => getElectives('minors', elective.getCourseCode(), catalog)));

    const data = await loadFile({dir: 'minors', file:'Credits-Minors.csv'});
    if(data === undefined) {
        throw new Error('Unable to load data credit totals for minors');
    }

    const dataLines = parseLines(data, ',');

    if(dataLines.length <= 0) {
        throw new Error('Unable to load data credit totals for minors');
    }
    const minorCredits = {};
    dataLines.forEach(entry => minorCredits[entry[0]] = entry[1]);
    const minorsMap = new Map();
    minorCourses.forEach(entry => minorsMap.set(entry[0], {
        credits: minorCredits[entry[0]],
        code: entry[0],
        courses: entry[1],
        name: MinorByCode[entry[0]]
    }));

    return minorsMap;
}
