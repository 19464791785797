import { loadFile, parseLines } from './FileIO';
import Track from '../model/Track';
import TrackCourse from '../model/TrackCourse';
import CatalogCourse from '../model/CatalogCourse';

const termCodeFromIndexAndCourse = (termIndex, courseCode) => (
    courseCode.length === 2 && termIndex === '1' ? 'Q1' :
    courseCode.length === 2 && termIndex === '2' ? 'Q2' :
    courseCode.length === 2 && termIndex === '3' ? 'Q3' :
    courseCode.length === 2 ? 'Q4' :
    courseCode.length === 3 && termIndex === '1' ? 'S1' :
    courseCode.length === 3 && termIndex === '2' ? 'S2' :
    courseCode.length === 3 ? 'S3' : ''
);

export const importTrack = async (major, trackAbbreviation, catalog) => {

    const data = await loadFile({dir: major.toLowerCase(), file:`${trackAbbreviation}.csv`});
    if(data === undefined) {
        throw new Error(`Unable to load data for track: ${trackAbbreviation}`);
    }

    const dataLines = parseLines(data, ',');

    // Retrieve track name from first course entry
    if(dataLines.length <= 0) {
        throw new Error(`No lines within data for track: ${trackAbbreviation}`);
    }

    const url = dataLines[0][0].startsWith('http') ? dataLines[0][0] : '';
    if(dataLines[0][0].startsWith('http')) {
        dataLines.shift();
    }

    // Build the track
    const trackName = dataLines[0][0];
    const courses = [];
    dataLines.forEach((courseEntry) => {
        const prefix = courseEntry[3];
        const number = courseEntry[4];

        // Search for the course in the catalog, if not found build a placeholder
        const courseCode = `${prefix}-${number}`;
        const course = catalog.findCourse(courseCode) || new CatalogCourse({
            prefix,
            number,
        });
        courses.push(new TrackCourse({
            track: courseEntry[0],
            year: parseInt(courseEntry[1]),
            term: termCodeFromIndexAndCourse(courseEntry[2], courseEntry[3]),
            course,
            required: courseEntry[5] === 'Y'
        }))
    });

    return new Track(
        {
            major: major.toLowerCase(),
            name: trackName,
            url,
            courses,
        }
    );
}
