import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateLeft, faCirclePlus, faToggleOn, faToggleOff, faTrash } from '@fortawesome/free-solid-svg-icons';

import { history } from '../App';
import { DisplayGrid, DisplayGridElement, DroppableGridElement } from './DisplayGrid';
import DisplayHeader from './DisplayHeader';
import { CoursePill, DraggableCoursePill } from './CoursePill';
import CourseWarningList, { CourseErrorWarning } from './CourseWarningList';
import AddCourseButton from './AddCourseButton';
import Popover from './Popover';

import {
    yearToHeading,
    TermByCode,
    SpecialYears,
    yearToSummerTermCode,
    maxTermsPerYear,
    DragDropTypes,
    isQuarterTerm,
    QuarterDragDrop,
    isSemesterTerm,
    SemesterDragDrop
} from '../model/Dictionaries';
import { stringCompare } from '../utilities';
import HistoryCourse from "../model/HistoryCourse";

const TableFooter = styled.div`
  @media print {
    display: none;
    width: min-content;
  }
`;

const AddSummer = styled.div`
  cursor: default;
  white-space: nowrap;
  @media print {
    display: none;
    width: min-content;
  }
`;

const AddYear = styled.div`
  white-space: nowrap;
  font-weight: bold;
  padding: .7em;
  cursor: default;
`;

const ResetScheduling = styled.div`
  white-space: nowrap;
  font-weight: bold;
  padding: .7em;
  cursor: default;
`;

const ScheduledSlider = styled.div`
    @media print {
      display: none;
      width: min-content;
    }
`;

const Footer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: end;
  justify-content: space-between;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  color: var(--main-color) !important;
`;

const CourseScheduleTermHeader = ({termNames, year}) => (
    // Don't include term header for AP/CLEP/TR/etc...
    termNames.join('').length > 1 && /[QS]\d$/.test(termNames[0]) ?
        <>
            <DisplayGridElement heading></DisplayGridElement>
            {termNames.sort(stringCompare).map((termName) => (
                <DisplayGridElement key={`TermHeading ${termName}${year}`} heading>
                    {TermByCode[termName]}
                </DisplayGridElement>))}
        </> : <></>
);

const CourseSchedulingTableFooter = ({onAddYear, onTrashCourse, onClearScheduling}) => (
    <TableFooter>
    <DisplayGrid stretch={1} columns={3}>
        <DisplayGridElement>
            <AddYear onClick={onAddYear}>
                <Popover popoverContent='Add year to Course Scheduling' location='left'>
                    <FontAwesomeIcon icon={faCirclePlus} />&nbsp;Add Year
                </Popover>
            </AddYear>
        </DisplayGridElement>
        <DroppableGridElement
            year={1000}
            term='RM'
            horizontal
            heading
            accept={[DragDropTypes.QuarterCourse, DragDropTypes.SemesterCourse, DragDropTypes.SemesterUnassignedElective, DragDropTypes.QuarterUnassignedElective]}
            onDrop={onTrashCourse}
        >
            <div>
                <Popover popoverContent='Drag course here to remove'>
                    <FontAwesomeIcon size='2x' icon={faTrash} />
                </Popover>
            </div>
        </DroppableGridElement>
        <DisplayGridElement>
            <ResetScheduling onClick={onClearScheduling}>
                <Popover popoverContent='Start over with schedule planning' location='right'>
                    Reset Scheduling&nbsp;<FontAwesomeIcon icon={faArrowRotateLeft} />
                </Popover>
            </ResetScheduling>
        </DisplayGridElement>
    </DisplayGrid>
    </TableFooter>
);

/**
 * Course Pill for the scheduling table
 *    Creates either a draggable or non-draggable course element based on status
 * @param courseEntry the course object to display
 * @param onElectiveSelect function to call when an elective is selected for a course
 * @constructor
 */
const CourseScheduleTablePill = ({courseEntry, onElectiveSelect}) => {
    // const isConflicted =
    //     courseEntry.unsatisfiedPrereqs.length > 0 ||
    //     courseEntry.prereqForList.length > 0 ||
    //     courseEntry.unsatisfiedCoreqs.length > 0 ||
    //     courseEntry.coreqForList.length > 0;
    const additionalProps = courseEntry.isElective() ?
        {
            title: <>{`${courseEntry.getCourseName()} ${courseEntry.getCourseStructure()}`}<br/>Right click to assign course</>,
            menuOptions: {
                year: courseEntry.year,
                term: courseEntry.term,
                electiveCode: courseEntry.getElectiveCode(),
                placeholder: `Select a course for ${courseEntry.getElectiveCode()}`,
                onMenuOptionSelect: (elective) => {
                    const electiveCourse = elective && elective instanceof HistoryCourse
                        ? elective.course : elective;
                    onElectiveSelect(courseEntry, electiveCourse);
                },
            }
        } :
        {
            title: `${courseEntry.getCourseName()} ${courseEntry.getCourseStructure()}`
        };

    const CourseComponent = courseEntry.canDragDrop() ? DraggableCoursePill : CoursePill;
    return (
        <div>
            <CourseComponent
                id={courseEntry}
                type={courseEntry.getDragDropType()}
                status={courseEntry.status}
                {...additionalProps}
                // conflicted={isConflicted}
            >
                {courseEntry.getCourseCode()}
            </CourseComponent>
            <CourseWarningList courseEntry={courseEntry} />
        </div>
    );
}

const CourseScheduleTableElementFooter = ({year, term, canAdd, canUnlock, canClear, onAddSelect, onToggleTermScheduled, totalCredits}) => (
    <Footer>
        {canAdd ?
            <ScheduledSlider onClick={() => onToggleTermScheduled(year, term, true)}>
                <Popover popoverContent='Mark as planned'><FontAwesomeIcon icon={faToggleOff} /></Popover>
            </ScheduledSlider> :
            canUnlock ?
                <ScheduledSlider onClick={() => onToggleTermScheduled(year, term, false)}>
                    <Popover popoverContent='Allow modifications to term'><FontAwesomeIcon icon={faToggleOn} /></Popover>
                </ScheduledSlider> :
           canClear ?
               <ScheduledSlider onClick={() => onToggleTermScheduled(year, term, false)}>
                   <Popover popoverContent='Clear registration for this term (cannot be undone)'><FontAwesomeIcon icon={faToggleOn} /></Popover>
               </ScheduledSlider> : <></>
        }
        <span>
            {canAdd && onAddSelect &&
                <AddCourseButton
                    year={year}
                    term={term}
                    onAddSelect={onAddSelect}
            />}
        </span>
        <span>Total: {totalCredits}
            {/^[QT][1-4]$/.test(term) && totalCredits > 19 ?
                <>
                    &nbsp;
                    <CourseErrorWarning
                        type='creditLoad'
                        severity='error'
                        title='Above full-time load'
                    />
                </> : <></>}
        </span>
    </Footer>
);

/**
 * Course table element
 * @param year the year for the element (from the row)
 * @param term the term for the element
 * @param courseEntries the list of courses to display
 * @param onDrop function to call when a course is dropped on this element
 * @param onAddSelect function to call when a course is added to this element
 * @param onClearWIPTerm function to call to make WIP courses be unscheduled (so changes can be made)
 * @param onToggleTermScheduled function to call when toggling whether a term is locked to changes (scheduled)
 * @param onElectiveSelect function to call when an elective is selected for a course
 * @returns {JSX.Element} the rendered element
 * @constructor
 */
const CourseScheduleTableElement = ({year, term, courseEntries, onDrop, onAddSelect, onClearWIPTerm, onToggleTermScheduled, onElectiveSelect}) => {

    const canDrop = onDrop && history.canScheduleTerm(year, term);
    const canUnlock = history.canModifyCoursesInTerm(year, term);
    const canClear = history.isWIPTerm(year, term);
    const GridComponent = canDrop ? DroppableGridElement : DisplayGridElement;
    const acceptableTypes =
        canDrop && isQuarterTerm(term) ? QuarterDragDrop :
        canDrop && isSemesterTerm(term) ? SemesterDragDrop :
        undefined;
    const horizontal = SpecialYears.includes(year);
    const totalCredits = courseEntries.reduce((accum, value) => accum + value.course.credits, 0);
    return (
        <GridComponent
            year={year}
            term={term}
            horizontal={horizontal}
            onDrop={onDrop}
            accept={acceptableTypes}
        >
            {courseEntries.sort((a, b) => stringCompare(a.getCourseCode(), b.getCourseCode()))
                .map((entry) => (
                    <CourseScheduleTablePill
                        key={`${entry.course.getCourseCode()}_${entry.status}_${entry.id}`}
                        courseEntry={entry}
                        onElectiveSelect={onElectiveSelect}
                    />
                ))}
            <CourseScheduleTableElementFooter
                year={year}
                term={term}
                canAdd={canDrop}
                canUnlock={canUnlock}
                canClear={canClear}
                onClearWIPTerm={onClearWIPTerm}
                onToggleTermScheduled={onToggleTermScheduled}
                onAddSelect={onAddSelect}
                totalCredits={totalCredits}
            />
        </GridComponent>
    );
}

/**
 * Row of a course scheduling table, represents a year (or AP/TR, etc).
 * @param year the year for the row
 * @param terms array of term names for the year
 * @param courseEntries entries to display in the row
 * @param onDrop function to call when a course is dropped in this row
 * @param canAddSummer true if summer term is in the future
 * @param onAddSummer function to call when adding a summer term
 * @param onAddSelect function to call when a course is added to this element
 * @param onClearWIPTerm function to call to make WIP courses be unscheduled (so changes can be made)
 * @param onToggleTermScheduled function to call when toggling whether a term is locked to changes (scheduled)
 * @param onElectiveSelect function to call when an elective is selected for a course
 * @returns {JSX.Element} the rendered row
 * @constructor
 */
const CourseScheduleTableRow = ({year, terms, courseEntries, onDrop, canAddSummer, onAddSummer, onAddSelect, onClearWIPTerm, onToggleTermScheduled, onElectiveSelect}) => (
    <React.Fragment key={`YearHeading ${year}`}>
        <DisplayGridElement heading>
            {yearToHeading(year)}
            {canAddSummer ? <AddSummer onClick={() => onAddSummer(year)}><Popover popoverContent='Add Summer term for this academic year' location='left'><FontAwesomeIcon icon={faCirclePlus} />&nbsp;Add Summer</Popover></AddSummer> : <></>}
        </DisplayGridElement>
        {terms.sort(stringCompare).map((term) => (
            <CourseScheduleTableElement
                year={year}
                term={term}
                courseEntries={courseEntries.filter(entry => entry.year === year && entry.term === term)}
                onDrop={onDrop}
                onAddSelect={onAddSelect}
                onClearWIPTerm={onClearWIPTerm}
                onToggleTermScheduled={onToggleTermScheduled}
                onElectiveSelect={onElectiveSelect}
                key={`YearTerm ${year}${term}`}
            />
        ))}
    </React.Fragment>
);

/**
 * Component that displays the scheduling table with movable courses.
 * @param structure map of the table structure (years and terms)
 * @param onCourseMove function to call when a movable course is dropped
 * @param onAddSelect function to call when a course is added to this element
 * @param onElectiveSelect function to call when an elective is selected for a course
 * @param onClearWIPTerm function to call to make WIP courses be unscheduled (so changes can be made)
 * @param onToggleTermScheduled function to call when toggling whether a term is locked to changes (scheduled)
 * @param onAddSummer function to call to add a summer term
 * @param onAddYear function to call to add year to course scheduling
 * @param onClearScheduling function to call to clear all scheduled courses
 * @returns {JSX.Element} The table to be rendered
 * @constructor
 */
const CourseSchedulingTable = ({structure, onCourseMove, onAddSelect, onElectiveSelect, onClearWIPTerm, onToggleTermScheduled, onAddSummer, onAddYear, onClearScheduling}) => {

    if(!structure) {
        return <></>;
    }

    /**
     * Determines the ranges for different term headings
     * @param termNames map of years to term names
     * @returns {number[][]} range separations
     */
    const computeYearIndexRanges = (termNames) => {
        const years = [...termNames.keys()].sort();
        const yearIndexRanges = years.length > 0 ? [[0, 0]] : [];
        years.forEach((year, index) => {
            if(index === 0) {
                return;
            }
            const currYearTermNames = termNames.get(years[index]).sort(stringCompare).join('');
            const prevYearTermNames = termNames.get(years[index-1]).sort(stringCompare).join('');
            if(currYearTermNames !== prevYearTermNames) {
                yearIndexRanges[yearIndexRanges.length-1][1] = index-1;
                yearIndexRanges.push([index, index]);
            } else if(index === years.length - 1) {
                yearIndexRanges[yearIndexRanges.length-1][1] = index;
            }
        });
        return yearIndexRanges;
    }

    const years = [...structure.keys()].sort();
    const yearRangesForGrid = computeYearIndexRanges(structure);

    return (
        <>
            <DisplayHeader>Course Scheduling</DisplayHeader>
            {
                yearRangesForGrid.map((range) => (
                    <DisplayGrid key={`GridRange ${range[0]}${range[1]}`} shrink={0} columns={structure.get(years[range[0]]).length + 1}>
                        <CourseScheduleTermHeader
                            termNames={structure.get(years[range[0]])}
                            year={years[range[0]]}
                        />
                        {years.slice(range[0], range[1]+1).map((year) => (
                            <CourseScheduleTableRow
                                key={`YearHeading ${year}`}
                                year={year}
                                terms={structure.get(year)}
                                courseEntries={history.courses.filter(entry => entry.year === year)}
                                canAddSummer={maxTermsPerYear(year) !== structure.get(year).length && history.canModifyCoursesInTerm(year, yearToSummerTermCode(year))}
                                onDrop={onCourseMove}
                                onAddSummer={onAddSummer}
                                onAddSelect={onAddSelect}
                                onClearWIPTerm={onClearWIPTerm}
                                onToggleTermScheduled={onToggleTermScheduled}
                                onElectiveSelect={onElectiveSelect}
                            />
                        ))}
                    </DisplayGrid>))
            }
            <CourseSchedulingTableFooter
                onAddYear={onAddYear}
                onTrashCourse={onCourseMove}
                onClearScheduling={onClearScheduling}
            />
        </>
    );
};
CourseSchedulingTable.propTypes = {
    structure: PropTypes.instanceOf(Map),
    onCourseMove: PropTypes.func,
};

export default CourseSchedulingTable;
