import DisplayHeader from "./DisplayHeader";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const NegIndent = styled.p`
  margin-left: 6.2em;
  margin-top: .2em;
  margin-bottom: .4em;
  text-indent: -6.2em;
`;

const Comments = ({commentLines, title, token}) => {
    const lines = commentLines?.sort().reverse().filter((line) => line.charAt(0) === token);
    return lines && lines.length > 0 ?
        <>
            <br /><DisplayHeader>{title}</DisplayHeader>
            {lines.map((line) => {
                const index = line.indexOf(' ', 2);
                return <NegIndent><b>{line.substring(2, 12)}</b> {line.substring(index)}</NegIndent>;
            })}
        </> : <></>;
}

Comments.propTypes = {
    commentLines: PropTypes.array,
    title: PropTypes.string,
    token: PropTypes.string
};

export default Comments;