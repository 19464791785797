import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PopoverText = styled.div`
  display: ${props => props.visibleForSize || props.visible ? 'block' : 'none'};
  cursor: default;
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  position: absolute;
  top: ${props => `${props.top}px`};
  left: ${props => `${props.left}px`};
  width: auto;
  padding: 6px;
  color: var(--main-color);
  background: var(--msoe-red-color);
  font-weight: bold;
  box-shadow: 0 0 5px var(--box-shadow-color);
  border-radius: 20px;
  border: var(--msoe-red-color);
  font-size: 90%;
  white-space: pre;
  z-index: 999999 !important;
  @media print {
    display: none;
    visibility: hidden;
  }
`;
PopoverText.propTypes = {
    visible: PropTypes.bool,
    visibleForSize: PropTypes.bool,
    top: PropTypes.number,
    left: PropTypes.number,
}

const Popover = (props) => {

    // Extract props
    const {
        popoverContent,
        location,
        visibilityOverride,
        children,
        ...otherProps
    } = props;

    const elementRef = useRef(null);
    const popoverRef = useRef(null);

    // State options for popover (tooltip)
    const [popTop, setPopTop] = useState(0);
    const [popLeft, setPopLeft] = useState(0);
    const [visible, setVisible] = useState(false);
    const [visibleForSize, setVisibleForSize] = useState(true);
    const [delayHandler, setDelayHandler] = useState();
    const [popoverLocation, setPopoverLocation] = useState({ height: 0, width: 0 });

    useEffect(() => {
        if(popoverRef && popoverRef.current) {
            setPopoverLocation(popoverRef.current.getBoundingClientRect());
            setVisibleForSize(false);
        }
    }, []);

    const handleMoveEvent = useCallback((event) => {
        const isOverTarget = (elementRef, target) => (
            elementRef || !elementRef.current || target.innerText !== elementRef.current.childNodes[0].innerText
        );
        if (!isOverTarget(elementRef, event.target) && !isOverTarget(popoverRef, event.target)) {
            clearTimeout(delayHandler);
            document.removeEventListener("mousemove", handleMoveEvent);
            setVisible(false);
        }
    }, [delayHandler]);

    const displayHidePopover = (visibility) => {
        if(visibility) {
            setDelayHandler(setTimeout(() => {
                if(elementRef && elementRef.current && popoverRef && popoverRef.current) {
                    const elementLocation = elementRef.current.getBoundingClientRect();

                    // Determine the relative positioning
                    //   for directions on top, bottom, left, and right
                    const possibleLocations = {
                        left: {
                            top: parseInt(((elementLocation.height / 2) - (popoverLocation.height / 2)).toFixed()),
                            left: parseInt((elementLocation.width).toFixed()),
                        },
                        right: {
                            top: parseInt(((elementLocation.height / 2) - (popoverLocation.height / 2)).toFixed()),
                            left: parseInt((0 - popoverLocation.width).toFixed()),
                        },
                        bottom: {
                            top: parseInt((elementLocation.height).toFixed()),
                            left: 0
                        },
                        top: {
                            top: parseInt((0 - popoverLocation.height).toFixed()),
                            left: 0,
                        }
                    }

                    // Prioritize user set location if specified
                    const popupLocation = location ? possibleLocations[location] : undefined;

                    // Default to left but move the popover to the right if it goes off the screen to the left
                    const finalLocation = popupLocation ? popupLocation :
                        possibleLocations.left.left > 0 && (elementLocation.right + popoverLocation.width) > document.body.clientWidth ? possibleLocations.right : possibleLocations.left;

                    setPopTop(finalLocation.top);
                    setPopLeft(finalLocation.left);
                    setVisible(visibility);

                    document.addEventListener("mousemove", handleMoveEvent);
                }
            }, 200));
        } else {
            clearTimeout(delayHandler);
            document.removeEventListener("mousemove", handleMoveEvent);
            setVisible(visibility);
        }
    }

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { ...otherProps });
        }
        return child;
    });

    const isPopOverVisible = visibilityOverride && visible;
    return (
        <span
            style={{
                width: 'max-content',
                position: 'relative',
            }}
            ref={elementRef}
            onMouseEnter={() => displayHidePopover(true)}
            onMouseLeave={() => displayHidePopover(false)}
        >
            {childrenWithProps}
            <PopoverText ref={popoverRef} top={popTop} left={popLeft} visible={isPopOverVisible} visibleForSize={visibleForSize}>
                {popoverContent}
            </PopoverText>
        </span>
    );
};
Popover.propTypes = {
    popoverContent: PropTypes.node,
    visibilityOverride: PropTypes.bool,
    location: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
};
Popover.defaultProps = {
    visibilityOverride: true,
};

export default Popover;
