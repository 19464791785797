import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select, { createFilter } from 'react-select';

const PopupMenu = styled.div`
  display: ${props => props.visible ? 'block' : 'none'};
  position: absolute;
  width: ${props => `${props.popupWidth}px`};
  top: ${props => `${props.top}px`};
  left: ${props => `${props.left}px`};
  white-space: nowrap;
  z-index: 999999 !important;
  font-weight: normal;
  background-color: var(--main-color);
  @media print {
    visibility: hidden;
  }
`;
PopupMenu.propTypes = {
    visible: PropTypes.bool,
    top: PropTypes.number,
    left: PropTypes.number,
    width: PropTypes.number,
}

const PopupMenuItem = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--main-background-color);
  @media print {
    visibility: hidden;
  }
`;

const ContextMenu = (props) => {

    const {
        placeholder,
        options,
        leftClick,
        rightClick,
        onMenuOpen,
        onMenuClose,
        menuWidth,
        onMenuOptionSelect,
        visibilityOverride,
        MenuItemComponent,
        children,
        ...otherProps
    } = props;

    const elementRef = useRef(null);

    // State options for context menu
    const [menuTop, setMenuTop] = useState(0);
    const [menuLeft, setMenuLeft] = useState(0);
    const [visible, setVisible] = useState(false);

    // Callback for to display or hide the meniu
    const displayHideMenu = useCallback((visibility) => {
        if(visibility) {
            if(elementRef && elementRef.current) {
                const location = elementRef.current.getBoundingClientRect();
                setMenuTop(parseInt((location.height + 5).toFixed()));
                setMenuLeft(0);
                setVisible(visibility);
                onMenuOpen && onMenuOpen();
            }
        } else {
            setVisible(visibility);
            onMenuClose && onMenuClose();
        }
    }, [onMenuOpen, onMenuClose]);

    // Use effect sets handler for clicking 'away' from the menu
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (elementRef && elementRef.current && !elementRef.current.contains(e.target)) {
                if(visible) {
                    onMenuOptionSelect();
                }
                displayHideMenu(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [elementRef, visible, onMenuOptionSelect, displayHideMenu]);

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { ...otherProps });
        }
        return child;
    });

    const clickHandler = (e) => {
        e.preventDefault();
        displayHideMenu(true);
    };

    const isMenuVisible = visibilityOverride && visible;
    return (
        <span
            style={{
                width: 'max-content',
                position: 'relative',
            }}
            ref={elementRef}
            onContextMenu={rightClick && !isMenuVisible ? clickHandler : undefined}
            onClick={leftClick && !isMenuVisible ? clickHandler : undefined}
        >
            {childrenWithProps}
            {isMenuVisible &&
                <PopupMenu top={menuTop} left={menuLeft} popupWidth={menuWidth} visible={isMenuVisible}>
                    <Select
                        defaultMenuIsOpen={true}
                        autoFocus={true}
                        closeMenuOnSelect={false}
                        closeMenuOnScroll={false}
                        menuIsOpen={true}
                        isClearable={false}
                        filterOption={createFilter({ ignoreCase: false })}
                        // styles={ContextStyles}
                        components={{
                            DropdownIndicator: undefined,
                        }}
                        placeholder={placeholder}
                        // Work in progress currently all users of this component provide the correct
                        //    options directly
                        // options={options.map(entry => ({
                        //     label: <MenuItemComponent>{entry.label}</MenuItemComponent>,
                        //     value: entry.value
                        // }))}
                        options={options}
                        onChange={(option, eventType) => {
                            if(eventType.action === 'select-option') {
                                displayHideMenu(false);
                                onMenuOptionSelect(option.value);
                            }
                        }}
                    />
                </PopupMenu>
            }
        </span>
    );
}
ContextMenu.propTypes = {
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    leftClick: PropTypes.bool,
    rightClick: PropTypes.bool,
    menuWidth: PropTypes.number,
    onMenuOpen: PropTypes.func,
    onMenuClose: PropTypes.func,
    onMenuOptionSelect: PropTypes.func,
    visibilityOverride: PropTypes.bool,
    MenuItemComponent: PropTypes.elementType,
};
ContextMenu.defaultProps = {
    visibilityOverride: true,
    leftClick: false,
    rightClick: true,
    menuWidth: 250,
    MenuItemComponent: PopupMenuItem,
};

export default ContextMenu;
