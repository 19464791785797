import React from "react";
import styled from "styled-components";
import Popover from "./Popover";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleMinus, faCirclePlus} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import Comments from "./Comments";

const AddNote = styled.div`
  cursor: default;
  white-space: nowrap;
  @media print {
    display: none;
    width: min-content;
  }
`;

const DeleteNote = styled.div`
  cursor: default;
  white-space: nowrap;
  @media print {
    display: none;
    width: min-content;
  }
`;

const NoteEntry = styled.input`
  font-size: 1.3em;
  margin: 0.25em;
  padding: 3px;
  background-color: #e6e6e6;
  border-radius: 8px;
  width: 98%;
  @media print {
    display: none;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: end;
  justify-content: space-between;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  color: var(--main-color) !important;
`;

const AddAdvisingNote = ({value, onKeyPress, onChange, onAddClick, onDeleteClick, commentLines}) => (
    <>
        <Comments commentLines={commentLines} title='Advising Notes' token='>'/>
        <NoteEntry type="text" value={value} onKeyPress={onKeyPress} onChange={onChange}/>
        <Buttons>
            <AddNote onClick={onAddClick}><Popover popoverContent="Add an advisor note. Alternatively, press enter in input field." location="left"><FontAwesomeIcon icon={faCirclePlus}/>&nbsp;Add Note</Popover></AddNote>
            <DeleteNote onClick={onDeleteClick}><Popover popoverContent="Delete all advisor notes for today" location="right"><FontAwesomeIcon icon={faCircleMinus}/>&nbsp;Delete Today's Notes</Popover></DeleteNote>
        </Buttons>
    </>
)

AddAdvisingNote.propTypes = {
    value: PropTypes.string,
    onKeyPress: PropTypes.func,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    commentLines: PropTypes.array
};

export default AddAdvisingNote;