import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";

import { DisplayGridElement, DisplayGrid } from "./DisplayGrid";
import { CoursePill } from "./CoursePill";
import Popover from "./Popover";
import AddMinorButton from "./AddMinorButton";
import { getMinorElectives } from "../model/Electives";
import { CourseCatalogContext } from '../App';
import { CourseErrorWarning } from "./CourseWarningList";

import { array_unique } from "../utilities";
import {Status, MinorTransitionURL, unsuccessfulCourse} from "../model/Dictionaries";

const RemoveMinor = styled.div`
    @media print {
      display: none;
      width: min-content;
    }
`;

const Footer = styled.div`
  align-items: end;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  color: var(--main-color) !important;
`;

const Minor = ({minor, history, onRemoveMinor}) => {
    const courseCatalog = useContext(CourseCatalogContext);
    const electives = array_unique(minor.courses.filter(course => course.isElective()));
    const electiveCourses = electives.map((course) => getMinorElectives(course.getCourseCode()))
        .flat().map((code) => courseCatalog.findCourse(code));
    const requiredCourses = minor.courses.filter(course => !course.isElective());
    const allCourses = requiredCourses.concat(electiveCourses);
    const relevantHistory = history.deepCopy();
    relevantHistory.courses = array_unique(allCourses.map(course => history.findMostRecentCourseEntry(course))
        .filter(hCourse => hCourse && !unsuccessfulCourse(hCourse.status))
        .concat(allCourses.map(course => history.findSufficientCourses(course)).flat().filter(hCourse => hCourse)));
    const totalCredits = Math.round(10 * relevantHistory.courses.map((hCourse) => hCourse.course.getSemesterCredits()).reduce((partialSum, a) => partialSum + a, 0)) / 10;
    const entries = minor.courses.map((c, index) => {

        const getCourseStatusAndName = (relevantHistory, courseEntry, isElective) => {
            const foundCourse = relevantHistory.findMostRecentCourseEntry(courseEntry);
            relevantHistory.removeCourse(foundCourse);
            return foundCourse ? isElective ? {status: foundCourse.status, title: foundCourse.course?.toString()} :
                {status: foundCourse.status, title: foundCourse.course?.courseName} : undefined;
        }

        const getSufficientCourseStatusAndName = (relevantHistory, courseEntry) => {
            const foundSufficientCourses = relevantHistory.findSufficientCourses(courseEntry);
            if (foundSufficientCourses && foundSufficientCourses.length > 0) {
                foundSufficientCourses.forEach(course => relevantHistory.removeCourse(course));
                return {
                    // Prioritize wip for status
                    status: foundSufficientCourses
                        .reduce((accum, courseEntry) => accum === Status.wip ? accum : courseEntry.status, Status.unscheduled),
                    // Set title to all courses that match
                    title: `Alternate: ${foundSufficientCourses.map(entry => entry.course.getCourseCode() + ' ' + entry.course.courseName).join(' / ')}`
                }
            }
            return undefined;
        }

        const getElectiveStatusAndName = (relevantHistory, courseEntry) => {
            if (!courseEntry.isElective()) {
                return undefined;
            }
            const courses = getMinorElectives(courseEntry.getCourseCode()).map(code => courseCatalog.findCourse(code));
            if (relevantHistory.courses.length === 0) {
                return {status: Status.missing, title: <>{courses.map(catalogCourse => catalogCourse.getDisplayableCourseCode()).join('\n')}</>};
            }
            for (const course of courses) {
                const result = getCourseStatusAndName(relevantHistory, course, true) ||
                    getSufficientCourseStatusAndName(relevantHistory, course);
                if (result) {
                    return result;
                }
            }
            return {status: Status.missing, title: <>{courses.map(catalogCourse => catalogCourse.getDisplayableCourseCode()).join('\n')}</>};
        }

        const statusTitle =
            getCourseStatusAndName(relevantHistory, c) ||
            getSufficientCourseStatusAndName(relevantHistory, c) ||
            getElectiveStatusAndName(relevantHistory, c) ||
            {status: Status.missing, title: c.courseName};

        return (
            <CoursePill key={`${c.getCourseCode()}_${index}`} status={statusTitle.status} title={statusTitle.title}>
                {c.getDisplayableCourseCode()}
            </CoursePill>
        );
    });
    const extras = relevantHistory.courses.map((c, index) => (
        <React.Fragment key={`${c.course.getCourseCode()}_${index}`}>
            <CoursePill status={c.status} title={c.course.courseName}>
                {c.getCourseCode()}
            </CoursePill>
            <CourseErrorWarning type='extra' severity='good' title='Not explicitly required by minor' />
        </React.Fragment>
    ));

    return (
        <>
            <DisplayGrid key={`GridRange ${minor.code}`} shrink={0} columns={2}>
                <DisplayGridElement heading>
                    <Popover popoverContent={<>STAT does not fully support minors.<br />
                        See <a target="_blank" rel="noreferrer" href={MinorTransitionURL[minor.code]}>transition plan details</a>.<br />
                        Please confirm requirements with the minor coordinator.
                    </>} location='bottom'>
                        {minor.name}
                    </Popover>
                    <RemoveMinor onClick={() => onRemoveMinor(minor.code)}>
                        <Popover popoverContent='Remove minor' location='bottom'><FontAwesomeIcon icon={faCircleMinus} /></Popover>
                    </RemoveMinor>
                </DisplayGridElement>
                <DisplayGridElement key={`${minor.code}-courses`} horizontal={true}>
                    {entries}
                    {extras}
                    <Footer>Total: {totalCredits}
                        {totalCredits < minor.credits ?
                            <>
                                &nbsp;
                                <CourseErrorWarning type='creditLoad'
                                                    severity='error'
                                                    description={`(${minor.credits} needed)`}
                                                    title={`Minor requires ${minor.credits}`} />
                            </> : <></>}
                    </Footer>
                </DisplayGridElement>
            </DisplayGrid>
        </>
    );
}

const Minors = ({history, minors, student, onAddMinor, onRemoveMinor}) => {
    return (
        <>
            {student.minors.map((minor) => (
                <Minor key={minor} minor={minors.get(minor)} history={history} onRemoveMinor={onRemoveMinor} />
            ))}
            <AddMinorButton
                student={student}
                onAddMinor={onAddMinor}
            />
        </>
    );
}
Minors.propTypes = {
    history: PropTypes.object,
    track: PropTypes.object,
    minors: PropTypes.instanceOf(Map),
    student: PropTypes.object,
    onAddMinor: PropTypes.func,
    onRemoveMinor: PropTypes.func,
}

export default Minors;
