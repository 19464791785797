import {uid} from '../utilities';

/**
 * Represents a course in a curriculum track.
 */
class TrackCourse {
    id = "";
    track = "";
    course = null;
    year = 0;
    term = "";
    required = true;
    alternative = "";

    constructor(data) {
        this.id = data.id || uid();
        this.track = data.track || "";
        this.course = data.course || null;
        this.year = data.year || 0;
        this.term = data.term || "";
        this.required = data.required === true;

        // let entries = line.split(',')
        // this.track = entries[0];
        // this.year = parseInt(entries[1]);
        // this.term = entries[2] === '1' ? 'Fall' :
        //     entries[2] === '2' && entries[3].length === 2 ? 'Winter' : 'Spring';
        // this._prefix = entries[3];
        // this._number = entries[4];
        // this.required = entries[5]==='Y';
        // this.alternative = entries[6];
    }

    deepCopy() {
        return new TrackCourse({
            id: this.id,
            track: this.track,
            course: this.course.deepCopy(),
            year: this.year,
            term: this.term,
            required: this.required,
        });
    }

    equals(otherCourse) {
        return (
            otherCourse &&
            this.id === otherCourse.id &&
            this.track === otherCourse.track &&
            this.course.equals(otherCourse.course) &&
            this.year === otherCourse.year &&
            this.term === otherCourse.term &&
            this.required === otherCourse.required
        );
    }

    isQuarterCourse() {
        return this.course.isQuarterCourse();
    }

    isSemesterCourse() {
        return this.course.isSemesterCourse();
    }

    getCourseCode() {
        return this.course?.getDisplayableCourseCode();
    }
}

export default TrackCourse;
