import { isRemoved, isSemesterTerm, LowestEnrollmentYear, SpecialYears, Status, successfulCourse, unsuccessfulCourse } from './Dictionaries';
import { array_unique, stringCompare } from '../utilities';
import CatalogCourse from './CatalogCourse';
import TrackCourse from './TrackCourse';
import HistoryCourse from './HistoryCourse';

// Advanced placement is 1 term (special case)
// Courses in all other years can be moved around
export const canChangeYear = (year) => (
    SpecialYears.findIndex(entry => entry === year) === -1
);

const FORWARD = true;
const REVERSE = false;

const completeYear = (terms) => (
    array_unique(/^Q\d$/.test(terms[0]) ? terms.concat(['Q1', 'Q2', 'Q3']) :
        /^T\d$/.test(terms[0]) ? terms.concat(['S1', 'S2', 'S3']) : terms).sort()
)

class CourseHistory {
    courses = [];

    constructor(data) {
        this.courses = data?.courses || [];
        this.firstModifieableTerm = null;
    }

    deepCopy() {
        return new CourseHistory({
            courses: this.courses.map(course => course.deepCopy())
        });
    }

    /**
     * Returns an array of all the years in which courses have been or are planned to be taken
     * @returns {*} an array of all the years in which courses have been or are planned to be taken
     */
    getYears() {
        return array_unique(this.courses.map(course => course.year)).sort();
    }

    /**
     * Returns all the terms in which courses have been or are planned to be taken for the specified year
     * @param year The year of interest
     * @returns {*} all the terms in which courses have been or are planned to be taken for the specified year
     */
    getTermsForYear(year) {
        return array_unique(this.courses.filter(course => course.year === year).map(course => course.term)).sort(stringCompare);
    }

    /**
     * Get all the courses listed in a given year and term
     * @param year Year of interest
     * @param term Term of interest
     * @returns {*[]} all the courses listed in a given year and term
     */
    getCoursesForYearTerm(year, term) {
        return this.courses.filter(course => course.year === year && course.term === term);
    }

    getTermNamesByYear() {
        return this.getYears().map(year => this.getTermsForYear(year));
    }

    /**
     * Returns the first year the student took a course at MSOE.
     * @returns {*} the first year the student took a course at MSOE.
     */
    getEntryYear() {
        return this.getYears().find((year) => year >= LowestEnrollmentYear);
    }

    /**
     * Remove any associations with a specific curriculum track
     */
    clearTrackAssignments() {
        // Remove unscheduled courses
        this.courses = this.courses.filter(c => c.status !== Status.unscheduled);
        // Remove elective courses
        this.courses = this.courses.filter(c => !c.isElective());

        // Clear elective assignments
        this.courses.forEach(c => c.elective = null);
        this.courses.forEach(c => c.trackCourse = null);
    }

    /**
     * Remove specified course from the course history
     * @param course Course of interest
     */
    removeCourse(course) {
        const index = this.courses.indexOf(course);
        if(index > -1) {
            this.courses.splice(index, 1);
        }
    }

    /**
     * Add a course to the course history
     * @param course Course of interest
     */
    addCourse(course) {
        this.firstModifieableTerm = null;
        const foundCourse = this.courses.find(searchEntry => searchEntry.id === course.id);
        if(foundCourse) {
            foundCourse.year = course.year;
            foundCourse.term = course.term;
            foundCourse.status = Status.unscheduled;
        } else {
            this.courses.push(course);
        }

        // If the course added is a sufficient for a course that is currently marked as Status.missing,
        //    Remove the course from the course history
        this.courses = this.courses.filter(searchEntry => (
            searchEntry.status !== Status.missing || !this.findSufficientCourses(searchEntry)?.length
        ));
    }

    /**
     * Move a course from one term to another
     * @param course Course of interest
     * @param year Year to be moved to
     * @param term Term to be moved to
     * @param courseCatalog course catalog (needed for finding sufficient electives)
     */
    moveCourse(course, year, term, courseCatalog) {
        const foundCourse = this.courses.find(searchEntry => searchEntry.equals(course));
        if(foundCourse) {
            foundCourse.year = year;
            foundCourse.term = term;
            if(isRemoved(year, term)) {
                foundCourse.status = Status.missing;
            } else if(foundCourse.course.isSemesterCourse() !== isSemesterTerm(term) && foundCourse.isElective()) {
                foundCourse.convertToSufficient(courseCatalog);
            }
        }
    }

    /**
     * Mark all courses in year/term as unscheduled
     * @param year Year of interest
     * @param term Term of interest
     */
    unlockTerm(year, term) {
        this.courses.forEach(c => {
            if (c.year === year && c.term === term) {
                c.status = Status.unscheduled;
            }
        });
    }

    /**
     * Mark all courses in year/term as scheduled
     * @param year Year of interest
     * @param term Term of interest
     */
    lockTerm(year, term) {
        this.courses.forEach(c => {
            if (c.year === year && c.term === term) {
                c.status = Status.scheduled;
            }
        });
    }

    _checkTermModifiable(year, term) {
        if(!canChangeYear(year)) {
            return false;
        }
        const coursesForYearTerm = this.getCoursesForYearTerm(year, term);
        return coursesForYearTerm.length === 0 ||
            coursesForYearTerm.every((course) => (
                course.status === Status.scheduled || course.status === Status.unscheduled
            ));
    }
    canScheduleTerm(year, term) {
        if(!canChangeYear(year)) {
            return false;
        }
        const coursesForYearTerm = this.getCoursesForYearTerm(year, term);
        return coursesForYearTerm.length === 0 ||
            coursesForYearTerm.some(course => course.status === Status.unscheduled);
    }
    canModifyCoursesInTerm(year, term) {
        const firstModifiableYearTerm = this.findFirstModifiableTerm();
        return this._checkTermModifiable(year, term) &&
            (firstModifiableYearTerm?.year < year ||
                (firstModifiableYearTerm?.year === year && firstModifiableYearTerm?.term <= term))
    }
    isWIPTerm(year, term) {
        const coursesForYearTerm = this.getCoursesForYearTerm(year, term);
        return coursesForYearTerm.length === 0 ||
            coursesForYearTerm.every((course) => (
                course.status === Status.wip
            ));
    }

    /**
     * Returns first term in which courses can be added or removed
     * @returns first term in which courses can be added or removed
     */
    findFirstModifiableTerm() {
        if(this.firstModifieableTerm) {
            return this.firstModifieableTerm;
        }
        const yearTerms = this.getYears().flatMap(year => completeYear(this.getTermsForYear(year)).map(term => ({ year, term })));
        const canModify = yearTerms.map(entry => this._checkTermModifiable(entry.year, entry.term));
        const lastModifiable = canModify.lastIndexOf(false);
        this.firstModifieableTerm = yearTerms[lastModifiable + 1];
        return this.firstModifieableTerm;
    }

    // T0DO
    attachElective(course, elective) {
        const foundCourse = this.courses.find(searchEntry => searchEntry.equals(course));
        if(foundCourse) {
            foundCourse.elective = elective.deepCopy();
            foundCourse.trackCourse = elective.deepCopy();
        }
    }

    // T0DO
    assignElective(course, elective) {
        const foundCourse = this.courses.find(searchEntry => searchEntry.equals(course));
        if(foundCourse && foundCourse.trackCourse) {
            if(elective) {
                foundCourse.course = elective.deepCopy();
                foundCourse.elective = foundCourse.trackCourse.deepCopy();
            } else {
                // NOTE: if the course being disassociated with the elective was a sufficient
                // course for a course in the track, this removal will not add the track course
                // back into the course history as a "missing" course. As a result, if this
                // track is exported and then uploaded again, the track course will be automatically
                // added back onto the course schedule (so it will be different than what was exported).
                foundCourse.course = foundCourse.trackCourse.course;
                foundCourse.elective = null;
            }
        }
        // If the course assigned to the elective is a sufficient for a course that is currently marked
        // as Status.missing, remove the course from the course history
        this.courses = this.courses.filter(searchEntry => (
            searchEntry.status !== Status.missing || !this.findSufficientCourses(searchEntry)?.length
        ));
    }

    /**
     * Get all electives that are not associated with specific courses
     * @returns {*[]} all electives that are not associated with specific courses
     */
    getEmptyElectives() {
        return this.courses
            .filter(courseEntry => courseEntry.isElective() && !courseEntry.elective && !courseEntry.trackCourse);
    }

    // T0DO
    assignTrackCourse(course, trackCourse) {
        const foundCourse = this.courses.find(searchEntry => searchEntry.equals(course));
        if(foundCourse && !foundCourse.trackCourse) {
            foundCourse.trackCourse = trackCourse;
        }
    }

    // T0DO
    covertAndAssignTrackCourse(course, trackCourse) {
        const foundCourse = this.courses.find(searchEntry => searchEntry.equals(course));
        if(foundCourse && !foundCourse.trackCourse) {
            const sufficientCourses = trackCourse.course.sufficients.getAsArray();
            if (!sufficientCourses || sufficientCourses.length > 1 || sufficientCourses.length === 0) {
                throw new Error(`Unable to determine equivalent for course ${foundCourse.getCourseName()}`);
            }
            foundCourse.trackCourse = trackCourse;
            foundCourse.trackCourse.course = sufficientCourses[0].deepCopy();
        }
    }

    _findCourseEntry(course, reference, direction, additionalFilter) {
        const sortStatus = (a, b) => (
            // Prioritize other statuses over unsuccessful
            b.status === Status.unsuccessful ? direction === FORWARD ? -1 : 1 :
            a.status === Status.unsuccessful ? direction === FORWARD ? 1 : -1 :
            0
        )
        const sortCourse = (a, b) => {
            if(a.year < b.year) {
                return -1;
            }
            if(b.year > a.year) {
                return 1;
            }
            const termCompare = stringCompare(a.term, b.term);
            if(termCompare === 0) {
                return sortStatus(a, b);
            }
            return termCompare;
        };
        const findSmallest = (array, compare) => (
            array.reduce((smallest, currentValue) => (
                !smallest ? currentValue :
                    compare(currentValue, smallest) === -1 ? currentValue : smallest
            ), null)
        );
        const foundCourses = this.courses.filter(searchCourse => course.equals(searchCourse[reference]));
        const additionalFiltered = additionalFilter ? foundCourses.filter(searchCourse => additionalFilter(searchCourse)) : foundCourses;
        const foreignCourse = additionalFiltered.find(course => SpecialYears.includes(course.year) && course.status === Status.successful);
        return (
            foreignCourse ? foreignCourse :
            direction === FORWARD ? findSmallest(additionalFiltered, (a, b) => sortCourse(a, b)) :
            direction === REVERSE ? findSmallest(additionalFiltered, (a, b) => sortCourse(b, a)) :
            undefined
        );
    }

    _findCourseEntryForType(courseEntry, direction) {
        // the following filter is used to search and only include courses that have neither an elective nor a reference track course set
        const electiveTrackCourseFilter = (courseEntry => !courseEntry.elective && !courseEntry.trackCourse);

        return (
            // History courses must be an exact match
            courseEntry instanceof HistoryCourse ? this.courses.find(searchEntry => searchEntry.equals(courseEntry)) :

            // Catalog course must be an exact match to the history course catalog reference
            courseEntry instanceof CatalogCourse ? this._findCourseEntry(courseEntry, "course", direction) :

            // Track courses are more complicated based on if they are an elective
            //   Checking must be done to find the track course in the elective reference or the track course reference (for unassigned elective)
            //   Courses that exist in the history without an elective or track course reference can find any catalog course that matches
            //       but ONLY if neither elective nor track reference is set
            courseEntry instanceof TrackCourse && courseEntry.course.isElective() ?
                this._findCourseEntry(courseEntry, "elective", direction) ||
                    this._findCourseEntry(courseEntry, "trackCourse", direction) ||
                        this._findCourseEntry(courseEntry.course, "course", direction, electiveTrackCourseFilter) :

            // Track courses that are not electives must be an exact match to the course
            courseEntry instanceof TrackCourse && !courseEntry.course.isElective() ? this._findCourseEntry(courseEntry.course, "course", direction) : undefined
        );
    }

    /**
     * Find a course in the course history searching forward
     * @param courseEntry Course of interest
     * @returns {*} Course in the course history
     */
    findCourseEntry(courseEntry) {
        return this._findCourseEntryForType(courseEntry, FORWARD);
    }

    /**
     * Find a course in the course history searching in reverse
     * @param courseEntry Course of interest
     * @returns {*} Course in the course history
     */
    findMostRecentCourseEntry(courseEntry) {
        return this._findCourseEntryForType(courseEntry, REVERSE);
    }

    _findSufficientCourses(course) {
        if(!course.sufficients) {
            return undefined;
        }
        return course.sufficients
            .satisfiesSet(this.getPotentialSufficientCourse())
            .getAsArray()
            .map(entry => this._findCourseEntryForType(entry, REVERSE))
            .filter(entry => entry !== undefined);
    }

    /**
     * Returns courses in the course history that are sufficient for the course of interest
     * @param courseEntry Course of interest
     * @returns {*|undefined} courses in the course history that are sufficient for the course of interest
     */
    findSufficientCourses(courseEntry) {
        return (
            courseEntry instanceof CatalogCourse ? this._findSufficientCourses(courseEntry) :
            courseEntry instanceof HistoryCourse || courseEntry instanceof TrackCourse ? this._findSufficientCourses(courseEntry.course) :
            undefined
        );
    }

    _checkMeets(courseEntry, key, filterFunc) {
        // Ignore courses without key value
        if(!courseEntry.course[key]) {
            return undefined;
        }
        // Find possible courses to satisfy key
        //   Possible courses must exist in the history, not be unsuccessful, and satisfy the filter function
        const possibleCourses = this.courses
            .filter(filterFunc)
            .filter(searchEntry => searchEntry.status !== Status.unsuccessful && searchEntry.status !== Status.missing)
            .flatMap(entry => entry.course);
        // Find the set of courses that do not meet the key rules
        return courseEntry.course[key]
            .unsatisfiesSet(possibleCourses, 'sufficients');
    }
    _checkMeetsPrereqs(courseEntry) {
        // Prereqs must be in a previous term
        const filterFunc = (searchEntry) => (
            searchEntry.year < courseEntry.year || (searchEntry.year === courseEntry.year && searchEntry.term < courseEntry.term)
        );
        return this._checkMeets(courseEntry, 'prereqs', filterFunc);
    }
    _checkMeetsCoreqs(courseEntry) {
        // Co-reqs must be in this or the previous term
        const filterFunc = (searchEntry) => (
            searchEntry.year < courseEntry.year || (searchEntry.year === courseEntry.year && searchEntry.term <= courseEntry.term)
        );
        return this._checkMeets(courseEntry, 'coreqs', filterFunc);
    }

    // T0DO
    getSufficientsSet(course) {
        const filterFunc = () => true;
        return this._checkMeets(course instanceof CatalogCourse ? new TrackCourse({course: course}) : course,
            'sufficients', filterFunc);
    }

    // T0DO
    checkAndSetConflicts(courseList) {
        courseList.forEach(courseEntry => {
            courseEntry.unsatisfiedPrereqs = this._checkMeetsPrereqs(courseEntry);
            courseEntry.unsatisfiedCoreqs = this._checkMeetsCoreqs(courseEntry);
        });
    }

    // T0DO
    conflictCheckAndUpdate() {

        // Ignore unsuccessful courses
        // Ignore courses before enrollment year (e.g. transfer)
        const enrollmentYear = this.getEntryYear();
        const coursesToCheck = this.courses.filter((courseEntry) => (
            courseEntry.status !== Status.unsuccessful &&
            courseEntry.status !== Status.missing &&
            courseEntry.year >= enrollmentYear
        ));
        this.checkAndSetConflicts(coursesToCheck);

        // Search and set the missing pre-requisites and co-requisites from course history
        this.courses.forEach(courseEntry => {
            courseEntry.prereqForList = [];
            courseEntry.coreqForList = [];
        });
        coursesToCheck.forEach((courseEntry) => {
            courseEntry.unsatisfiedPrereqs?.getAsArray().forEach((entry) => {
                const foundCourse = this.findCourseEntry(entry);
                if(foundCourse) {
                    foundCourse.prereqForList.push(courseEntry);
                }
            });
            courseEntry.unsatisfiedCoreqs?.getAsArray().forEach((entry) => {
                const foundCourse = this.findCourseEntry(entry);
                if(foundCourse) {
                    foundCourse.coreqForList.push(courseEntry);
                }
            });
        })
    }

    /**
     * Returns the name of the course
     * @param courseEntry Course of interest
     * @returns {*|string} the name of the course
     */
    getCourseName(courseEntry) {
        return this._findCourseEntryForType(courseEntry, FORWARD)?.getCourseName();
    }
    getAbbrCourseName(courseEntry) {
        return this._findCourseEntryForType(courseEntry, FORWARD)?.getAbbrCourseName();
    }

    getCourseCode(courseEntry) {
        return this._findCourseEntryForType(courseEntry, FORWARD)?.getCourseCode();
    }
    getAbbrCourseCode(courseEntry) {
        return this._findCourseEntryForType(courseEntry, FORWARD)?.getAbbrCourseCode();
    }

    getCourseStatus(courseEntry) {
        const foundCourse = this._findCourseEntryForType(courseEntry, REVERSE);
        return foundCourse ? foundCourse.status : Status.unscheduled;
    }

    /**
     * Returns all successfully completed courses
     * @returns {(null|*)[]} all successfully completed courses
     */
    getSuccessfulCourses() {
        return this.courses.filter((courseEntry) => successfulCourse(courseEntry.status)).map(courseEntry => courseEntry.course);
    }

    getPotentialSufficientCourse() {
        return this.courses.filter((courseEntry) => !unsuccessfulCourse(courseEntry.status)).map(courseEntry => courseEntry.course);
    }

    getAsArray() {
        return this.courses.map((courseEntry) => ({
            id: `${courseEntry.year}${courseEntry.term}${courseEntry.course.getCourseCode()}${courseEntry.status}`,
            year: courseEntry.year,
            term: courseEntry.term,
            status: courseEntry.status,
            course: courseEntry.course.deepCopy(),
            elective: courseEntry.elective ? courseEntry.elective.deepCopy() : undefined
        }));
    }
}

export default CourseHistory;
