import PropTypes from 'prop-types';

import DisplayHeader from './DisplayHeader';
import { CoursePill } from './CoursePill';
import { DisplayGrid, DisplayGridElement } from './DisplayGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { array_unique } from '../utilities';
import Track from '../model/Track';
import CourseHistory from '../model/CourseHistory';
import {Status, unsuccessfulCourse} from "../model/Dictionaries";
import React from "react";
import Popover from "./Popover";
import styled from "styled-components";

const RightArrow = styled.span`
  float: right;
  cursor: default;
  @media print {
    display: none;
    width: min-content;
  }
`;

const LeftArrow = styled.span`
  float: left;
  cursor: default;
  @media print {
    display: none;
    width: min-content;
  }
`;

/**
 * Component that displays the nominal transition track.
 * @param track A track object
 * @param history The course history for the student
 * @param handleTrackYearChange Callback for updating the transition track
 * @param student The student object
 * @returns {JSX.Element} The table to be rendered
 * @constructor
 */
const TrackTable = ({track, history, handleTrackYearChange, student}) => {

    if(!track || !history) {
        return <></>;
    }

    const years = [];
    for (let i = 1; i <= array_unique(track.courses.map(c => c.year)).length; i++) {
        years.push(new Set());
    }
    track.courses.forEach(c => years[c.year - 1].add(c.term));

    /**
     * Generates JSX for all the courses in a specific year/term
     * @param year Year of the curriculum track
     * @param term Term of the curriculum track
     * @returns {unknown[]}
     */
    const displayTermCourses = (year, term) => track.courses.filter(c => c.year === year && c.term === term)
        .map((c, index) => {

            const getCourseStatusAndName = (history, courseEntry) => {
                const foundCourse = history.findMostRecentCourseEntry(courseEntry);
                return foundCourse ? {
                    status: foundCourse.course.courseName === 'No Elective' ? Status.missing : foundCourse.status,
                    title: foundCourse.getCourseName()
                } : undefined;
            }

            const getSufficientCourseStatusAndName = (history, courseEntry) => {
                const foundSufficientCourses = history.findSufficientCourses(courseEntry);
                if(foundSufficientCourses && foundSufficientCourses.length > 0) {
                    return {
                        // Prioritize wip for status
                        status: foundSufficientCourses
                            .reduce((accum, courseEntry) => accum === Status.wip ? accum : courseEntry.status, Status.unscheduled),
                        // Set title to all courses that match
                        title: `Alternate: ${foundSufficientCourses.map(entry => entry.getCourseCode() + ' ' + entry.getCourseName()).join("-")}`
                    }
                }
                return undefined;
            }

            // Course status and name is based on the following heuristic
            // Search - Pass 1
            //   Search for the exact course (by course code) is found in the history, then retrieve the name and status
            const courseExactMatch = getCourseStatusAndName(history, c);

            // Search - Pass 2
            //   If the exact course (by course code) is not found OR the status of the found course is failed
            //     search for sufficient courses
            const sufficientCourseMatch = (!courseExactMatch || unsuccessfulCourse(courseExactMatch.status)) ?
                getSufficientCourseStatusAndName(history, c) : courseExactMatch;

            // Search - Pass 3
            //   If the results for searching in pass 2 result in no results or the found course is unsuccessful
            //      fall back to the default status and name
            const statusTitle =
                // Exact course match turned up a match, use the exact match
                courseExactMatch && !unsuccessfulCourse(courseExactMatch.status) ? courseExactMatch :
                // Found a successful match in sufficient courses
                sufficientCourseMatch && !unsuccessfulCourse(sufficientCourseMatch.status) ? sufficientCourseMatch :
                // Found an exact match, but it was unsuccessful AND found sufficient but that was also unsuccessful
                //    fall back to first (exact) match
                courseExactMatch ? courseExactMatch :
                // Neither exact course nor sufficient search found anything
                { status: Status.unscheduled, title: c.course.name };

            return (
                <CoursePill
                    key={`${c.getCourseCode()}_${index}`}
                    status={statusTitle.status}
                    title={statusTitle.title}
                >
                    {c.getCourseCode()}
                </CoursePill>
            );
        });

    /**
     * Generates JSX for all the terms for a given year
     * @param year Year of the curriculum track
     * @param terms All of the terms of the curriculum track
     * @returns {*}
     */
    const displayYearCourses = (year, terms) => terms.map(term => (
        <DisplayGridElement key={year + term}>{displayTermCourses(year, term)}</DisplayGridElement>));
    const displayLeftArrow = (student) => (
        !student.canDecrementTrackYear() ? <></> :
            (
                <LeftArrow onClick={() => handleTrackYearChange(-1)}>
                    <Popover popoverContent='Decrease the number of semester years' location='left'>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Popover>
                </LeftArrow>
            )
    )

    const displayRightArrow = (student) => (
        !student.canIncrementTrackYear() ? <></> :
            (
                <RightArrow onClick={() => handleTrackYearChange(1)}>
                    <Popover popoverContent='Increase the number of semester years' location='right'>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </Popover>
                </RightArrow>
            )
    )
    const displayMajor = (track) => (track.url === '' ? track.name : <a target="_blank" rel="noreferrer" href={track.url}>{track.name}</a>)

    const columns = years.reduce((accum, year) => (accum + year.size), 0);
    return (
        <>
            <DisplayHeader>{displayMajor(track)}</DisplayHeader>
            {displayLeftArrow(student)} {displayRightArrow(student)}
            <br />
            <DisplayGrid columns={columns}>
                {years.map((year, index) => (<DisplayGridElement heading key={index} className="tableElement" span={year.size}>{`Year ${index+1}`}</DisplayGridElement>))}
                {years.map(t => [...t]).map(terms => terms.map(term => (<DisplayGridElement heading key={term} className="tableElement">{term}</DisplayGridElement>)))}
                {years.map(t => [...t]).map((terms, index) => displayYearCourses(index + 1, terms))}
            </DisplayGrid>
        </>
    )
}

TrackTable.propTypes = {
    track: PropTypes.instanceOf(Track).isRequired,
    history: PropTypes.instanceOf(CourseHistory).isRequired,
}

export default TrackTable;
