import { DisplayGridElement, DisplayGrid } from "./DisplayGrid";
import React from "react";
import { CoursePill } from "./CoursePill";
import DisplayHeader from "./DisplayHeader";
import { stringCompare } from '../utilities';
import PropTypes from "prop-types";
import {futureCourse, SpecialYears, Status} from "../model/Dictionaries";
import Popover from "./Popover";

const GraduationAudit = ({topicalBuckets, history, track}) => {
    const getCourseInfo = (history, trackCourse) => {
        const foundCourse = history.findMostRecentCourseEntry(trackCourse);
        return foundCourse && foundCourse.status !== Status.missing ? {
            courseCode: foundCourse.getCourseCode(),
            status: foundCourse.status,
            title: foundCourse.getCourseName()
        } : undefined;
    }

    const getSufficientCourseInfo = (history, trackCourse) => {
        const foundSufficientCourses = history.findSufficientCourses(trackCourse);
        const unmetSufficients = history.getSufficientsSet(trackCourse);
        if (foundSufficientCourses && !unmetSufficients) {
            return {
                courseCode: trackCourse.course.getDisplayableCourseCode(),
                status: Status.unscheduled,
                title: `Replaced by: ${foundSufficientCourses.map(entry => entry.course.getDisplayableCourseCode() + ' ' + entry.course.courseName).join(' AND ')}`
            };
        }
        if (!trackCourse.required) {
            return {
                courseCode: trackCourse.course.getDisplayableCourseCode(),
                status: Status.unscheduled,
                title: trackCourse.course.courseName
            };
        }
        const alternativeText = unmetSufficients ? `OR ${unmetSufficients.toString()}` : '';
        return {
                courseCode: trackCourse.course.getDisplayableCourseCode(),
                status: Status.unsuccessful,
                title: `Must add ${trackCourse.course.getDisplayableCourseCode()} ${alternativeText} to meet graduation requirements`
        };
    }

    const entryToCourseInfo = (trackCourse) => {
        // Course status and name is based on the following heuristic
        // Search - Pass 1
        //   Search for the exact course (by course code) is found in the history, then retrieve the name and status
        const courseExactMatch = getCourseInfo(history, trackCourse);

        // Search - Pass 2
        //   If the exact course (by course code) is not found OR the status of the found course is failed
        //     search for sufficient courses
        const sufficientCourseMatch = (!courseExactMatch || courseExactMatch.status === Status.unsuccessful) ?
            getSufficientCourseInfo(history, trackCourse) : courseExactMatch;

        // Search - Pass 3
        //   If the results for searching in pass 2 result in no results or the found course is unsuccessful
        //      fall back to the default status and name
        return (
            // Exact course match turned up a match, use the exact match
            courseExactMatch && courseExactMatch.status !== Status.unsuccessful ? courseExactMatch :
            // Found a successful match in sufficient courses
            sufficientCourseMatch && sufficientCourseMatch.status !== Status.unsuccessful ? sufficientCourseMatch :
            // Found an exact match, but it was unsuccessful AND found sufficient but that was also unsuccessful
            //    fall back to first (exact) match
            courseExactMatch && sufficientCourseMatch && sufficientCourseMatch.status === Status.unsuccessful ? courseExactMatch :
            // Neither exact course nor sufficient search found anything
            sufficientCourseMatch
        );
    }

    const duplicateCheck = (course) => {
        const sufficientCourses = history.findSufficientCourses(course)
                ?.filter(entry => (entry.status !== Status.missing && entry.status !== Status.unsuccessful));
        const unmetSufficients = course.sufficients && course.sufficients.unsatisfiesSet(sufficientCourses.map(entry => entry.course), 'sufficients');
        const hasAllSufficients = sufficientCourses && sufficientCourses.length > 0 && !unmetSufficients;
        const hasSomeSufficients = unmetSufficients && sufficientCourses && sufficientCourses.length > 0;

        return {
            courseCode: course.getDisplayableCourseCode(),
            status:
                hasAllSufficients ? Status.unsuccessful : // wholly duplicated coursework
                hasSomeSufficients ? Status.missing :       // partially duplicated coursework
                Status.unscheduled,                       // unique coursework
            title:
                hasAllSufficients ? `Content already covered by ${sufficientCourses.map(entry =>
                entry.course.getDisplayableCourseCode() + ' ' + entry.course.courseName).join(' AND ')}.` :
                hasSomeSufficients ? `Content partially duplicated by ${sufficientCourses.map(entry =>
                entry.course.getDisplayableCourseCode() + ' ' + entry.course.courseName).join(' AND ')}.` :
                course.courseName
        };
    }

    const terms = history.courses.map(historyCourse => historyCourse.term.charAt(0));
    const isTransitionTrack = terms.includes('Q') && terms.includes('S');
    const creditCourses = history.courses.filter(historyCourse =>
        (historyCourse.status !== Status.unsuccessful && historyCourse.status !== Status.missing));
    const excludedTrackElectives = history.courses.filter(trackCourse =>
        (trackCourse.elective && trackCourse.course.getCourseCode().includes('XX-NONE')) || (trackCourse.isElective() && trackCourse.status === Status.missing))
        .map(course => course.trackCourse)
    const nonElectiveCourses = creditCourses.filter(historyCourse => !historyCourse.elective).map(historyCourse => historyCourse.course);
    const coveredElectives = creditCourses.filter(historyCourse => historyCourse.elective).map(historyCourse => historyCourse.elective).concat(excludedTrackElectives);
    const trackWithoutCoveredElectives = track.courses.filter(trackCourse => !coveredElectives.some(elective => elective.equals(trackCourse)));
    const electiveStatus = isTransitionTrack ? Status.unscheduled : Status.unsuccessful;
    const formattedExcludedElectives = history.courses.filter(trackCourse => (trackCourse.elective && trackCourse.course.getCourseCode().includes('XX-NONE')) || (trackCourse.isElective() && trackCourse.status === Status.missing))
        .map(trackCourse => trackCourse.elective ? trackCourse.elective.course : trackCourse.trackCourse.course)
        .map(course => ({
            courseCode: course.getDisplayableCourseCode(),
            status: electiveStatus,
            title: course.courseName}));
    const excludedCourses = trackWithoutCoveredElectives.filter(trackCourse => !nonElectiveCourses.some(course => course.equals(trackCourse.course)))
        .map(entryToCourseInfo).concat(formattedExcludedElectives);
    const addedCourses = nonElectiveCourses.filter(course => !trackWithoutCoveredElectives.some(trackCourse => trackCourse.course.getCourseCode() === course.getCourseCode()))
        .map(duplicateCheck);
    const schedCourses = history.courses.filter(courseEntry => futureCourse(courseEntry.status) && !courseEntry.course.isElective() &&
        !courseEntry.course.isOfferedByHistory(courseEntry.year, courseEntry.term, 0));
    const excludedExplanation = "Courses on the nominal track that are not part of the student's course scheduling plan";
    const addedExplanation = "Courses on the student's course scheduling plan that are not part of the nominal track";
    const schedExplanation = "Courses not explicitly listed as offered in the term they are currently placed";

    const removeDuplicates = (courses) => {
        const foreignCourses = courses.filter(hCourse => SpecialYears.includes(hCourse.year)).map(hCourse => hCourse.course);
        const localCourses = courses.filter(hCourse => !SpecialYears.includes(hCourse.year)).map(hCourse => hCourse.course);
        foreignCourses.forEach(course => {
            (course.repeatable || !localCourses.includes(course)) && localCourses.push(course);
        });
        return localCourses;
    }

    const getCreditThresholdTable = () => {
        // Update number of scheduled credits. This includes for-credit courses with any status other than 'unsuccessful/missing'
        // The status for the "credit pill" is colored:
        //  * 'unsuccessful' (RED) if scheduled credits do not meet the required threshold
        //  * 'unscheduled' (GREEN) if scheduled credits is over threshold but not by more than three credits
        //  * 'scheduled' (YELLOW) if scheduled credits exceed the threshold by at least 3 credits (may be able to remove a class)
        [...topicalBuckets.values()].forEach((bucket) => {
            const takenInBucket = history.courses.filter((entry) => !entry.noCredit && entry.status !== Status.unsuccessful &&
                entry.status !== Status.missing && bucket.courses.includes(entry.course.getCourseCode()));
            const deduppedInBucket = removeDuplicates(takenInBucket);
            bucket.planned = Math.round(10 * deduppedInBucket.map(course => course.getSemesterCredits())
                .reduce((partialSum, a) => partialSum + a, 0)) / 10;
            bucket.status = bucket.planned < bucket.required ? Status.unsuccessful : Status.successful;
        });
        return isTransitionTrack ?
        <DisplayGrid key='GridRange Credits' shrink={0} columns={topicalBuckets.size + 1}>
            <DisplayGridElement heading>Credit Thresholds</DisplayGridElement>
            {[...topicalBuckets.entries()].sort((a, b) => stringCompare(a[0], b[0])).map((entry) => (
                <DisplayGridElement horizontal printVertical key={`${entry[0]}credits`}>
                    {entry[0]}
                    <CoursePill status={entry[1].status}
                                description={entry[1].status === Status.unsuccessful && ` (${entry[1].required} needed)`}
                                title={entry[1].status === Status.unsuccessful ? `Below the ${entry[1].required} credit threshold` :
                                    `Threshold of ${entry[1].required} credits met`}>
                        {entry[1].planned}
                    </CoursePill>
                </DisplayGridElement>
            ))}
        </DisplayGrid> : <></>;
    };

    return (
        <>
            <DisplayHeader>Graduation Requirements</DisplayHeader>
            {getCreditThresholdTable()}
            <DisplayGrid key='GridRange Excluded' shrink={0} columns={2}>
                <DisplayGridElement heading><Popover popoverContent={excludedExplanation}
                                                     location='top'>Excluded</Popover></DisplayGridElement>
                <DisplayGridElement key='ExcludedList' horizontal printVertical>
                    {excludedCourses.sort((a, b) => stringCompare(a.courseCode, b.courseCode))
                        .map((entry, index) => (
                            <CoursePill
                                key={`${entry.courseCode}_${index}`}
                                status={entry.status}
                                description={entry.title}
                                title={entry.title}>
                                {entry.courseCode}
                            </CoursePill>
                        ))}
                </DisplayGridElement>
            </DisplayGrid>
            <DisplayGrid key='GridRange Added' shrink={0} columns={2}>
                <DisplayGridElement heading><Popover popoverContent={addedExplanation}
                                                     location='top'>Added</Popover></DisplayGridElement>
                <DisplayGridElement key='AddedList' horizontal>
                    {addedCourses.sort((a, b) => stringCompare(a.courseCode, b.courseCode))
                        .map((entry, index) => (
                            <CoursePill
                                key={`${entry.courseCode}_${index}`}
                                status={entry.status}
                                description={entry.title}
                                title={entry.title}>
                                {entry.courseCode}
                            </CoursePill>
                        ))}
                </DisplayGridElement>
            </DisplayGrid>
            <DisplayGrid key='GridRange SchedWarning' shrink={0} columns={2} hidePrint>
                <DisplayGridElement heading><Popover popoverContent={schedExplanation}
                                                     location='top'>Strict Scheduling</Popover></DisplayGridElement>
                <DisplayGridElement key='SchedList' horizontal>
                    {schedCourses.map((entry, index) => (
                            <CoursePill
                                key={`${entry.course.getCourseCode()}_${index}`}
                                title={`${entry.year} ${entry.term}`}>
                                {entry.course.getDisplayableCourseCode()}
                            </CoursePill>
                        ))}
                </DisplayGridElement>
            </DisplayGrid>
        </>
    );
}

GraduationAudit.propTypes = {
    major: PropTypes.string,
    catalog: PropTypes.object,
    topicalAreaMap: PropTypes.instanceOf(Map),
    history: PropTypes.object
}

export default GraduationAudit;
