import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ContextMenu from './ContextMenu';
import CourseWarningList from './CourseWarningList';

import { CourseCatalogContext, useTrackVersionContext, useHistoryVersionContext } from '../App';
import { history, track } from '../App';

import HistoryCourse from '../model/HistoryCourse';
import {isQuarterTerm, isSemesterTerm, Status, unsuccessfulCourse} from '../model/Dictionaries';
import { getElectives } from '../model/Electives';
import { historyCourseCompare } from '../utilities';

const CoursePopupMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--main-background-color);
  @media print {
    visibility: hidden;
  }
`;
const CoursePopupMenuItemTitle = styled.span`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
  text-decoration: ${props => props.textDecoration}
  @media print {
    visibility: hidden;
  }
`
CoursePopupMenuItemTitle.propTypes = {
    textDecoration: PropTypes.string,
};
CoursePopupMenuItemTitle.defaultProps = {
    textDecoration: undefined,
}

const getPossibleCourses = async ({year, term, track, courseCatalog, history, electiveCode}) => {

    // Step 1:
    // Build a list of course and filter based on term and eliminate elective codes

    // Build the set of base courses based on the course catalog or the elective code given
    const baseCourses = electiveCode ?
        (await getElectives(track.major, electiveCode.replace(' ', '-'), courseCatalog)).map(courseCode => courseCatalog.findCourse(courseCode)) :
        (await getElectives('common', 'ELC-ALL', courseCatalog)).map(courseCode => courseCatalog.findCourse(courseCode));

    // Build list of possible courses
    //  remove elective codes (EL and ELC) and filter by term type
    const possibleCourses = baseCourses
        .filter(courseEntry => !courseEntry.isElective());

    // Depending on the term filter by semester or quarter courses
    const filteredCoursesForTerm =
        isQuarterTerm(term) ? possibleCourses.filter(course => course.isQuarterCourse()) :
        isSemesterTerm(term) ? possibleCourses.filter(course => course.isSemesterCourse()) : [];

    // Step 2:
    // Filter the courses against the history and check for conflicts
    const historyFilteredCourses = history ?
        filteredCoursesForTerm.filter(courseEntry =>
            courseEntry.repeatable || !history.findCourseEntry(courseEntry)  ||
            unsuccessfulCourse(history.findCourseEntry(courseEntry)?.status)) :
        filteredCoursesForTerm;

    // Step 3:
    // Determine if the history has any missing electives (trashed)
    const historyMissingElectives = history ?
        history.courses.filter(courseEntry => courseEntry.isElective() && courseEntry.isRemoved()) : [];

    // Depending on the term filter by semester or quarter courses
    const historyMissingElectivesForTerm =
        isQuarterTerm(term) ? historyMissingElectives.filter(course => course.isQuarterCourse()) :
        isSemesterTerm(term) ? historyMissingElectives.filter(course => course.isSemesterCourse()) : historyMissingElectives;

    const historyAddedMissingElectives = electiveCode ? historyFilteredCourses :
        historyFilteredCourses.concat(historyMissingElectivesForTerm.map(historyCourse => historyCourse.course));

    // Selecting a course (specifically for adding a course later) should match up
    //   to an existing course if it was previously removed (e.g. electives and/or track required courses)
    //   To match this up, the id of the course is stored in the menu item
    //   Matching up a course should only be included for missing (removed) courses
    const withHistoryCourses = history ? historyAddedMissingElectives.map((courseEntry) => {
        if(courseEntry instanceof HistoryCourse) {
            return courseEntry;
        }
        const foundEntry = history.findMostRecentCourseEntry(courseEntry);
        return foundEntry && foundEntry.isRemoved() ? foundEntry : courseEntry;
    }) : historyAddedMissingElectives;

    // Build an array of courses simulating a course history
    const courseArray = withHistoryCourses.map(entry => (
        new HistoryCourse({
            id: entry instanceof HistoryCourse ? entry.id : undefined,
            year: year,
            term: term,
            course: entry instanceof HistoryCourse ? entry.course : entry,
            status: Status.unscheduled,
        })
    ));

    // Check the array against conflicts in the history
    if(history) {
        history.checkAndSetConflicts(courseArray);
    }

    return courseArray;
}

const CourseMenuItem = ({courseEntry}) => (
    <CoursePopupMenuItem>
       <CoursePopupMenuItemTitle
           textDecoration={courseEntry.unsatisfiedPrereqs || courseEntry.unsatisfiedCoreqs ? 'line-through' : undefined}
       >
            {courseEntry.course.toString()}
       </CoursePopupMenuItemTitle>
       <CourseWarningList
           courseEntry={courseEntry}
           includeTitle={false}
           severity='error'
       />
    </CoursePopupMenuItem>
);
CourseMenuItem.propTypes = {
    courseEntry: PropTypes.instanceOf(HistoryCourse),
};

const buildMenuOptions = (courseArray) => (
    courseArray.sort((a, b) => historyCourseCompare(a, b))
        .map(courseEntry => ({
            value: courseEntry,
            label: <CourseMenuItem courseEntry={courseEntry} />
        }))
);

const CourseContextMenu = (props) => {

    const trackVersion = useTrackVersionContext();
    const historyVersion = useHistoryVersionContext();
    const courseCatalog = useContext(CourseCatalogContext);

    const {
        year,
        term,
        electiveCode,
        onMenuOptionSelect,
        children,
        ...otherProps
    } = props;

    const [menuOptions, setMenuOptions] = useState([]);

    useEffect(() => {
        getPossibleCourses({year, term, track, history, courseCatalog, electiveCode})
            .then(courses => setMenuOptions(buildMenuOptions(courses)));
    }, [year, term, trackVersion, historyVersion, courseCatalog, electiveCode, setMenuOptions]);

    return (
        <ContextMenu
            options={menuOptions}
            {...otherProps}
            onMenuOptionSelect={(course) => {
                onMenuOptionSelect(course);
            }}
        >
            {children}
        </ContextMenu>
    );
}
CourseContextMenu.propTypes = {
    year: PropTypes.number.isRequired,
    term: PropTypes.string.isRequired,
    electiveCode: PropTypes.string,
    onMenuOptionSelect: PropTypes.func,
};

export default CourseContextMenu;
