import { loadFile, parseLines } from './FileIO';
import CatalogCourse, { EquivalenceSet, EquivalenceSetOp } from '../model/CatalogCourse';
import CourseCatalog from '../model/CourseCatalog';
import {array_unique} from '../utilities';

const buildLogicGroups = (data, courseCatalog) => {
    if(data && data.length > 0 && data !== '-') {

        // console.log(data);
        const matches = data
            .replace(/^[^(]*\(/, '') // trim everything before first parenthesis
            .replace(/\)[^)]*$/, '') // trim everything after last parenthesis

        const thisSet = new EquivalenceSet();
        let currSet = thisSet;

        // Special use of the 'replace' string function
        //   instead of actually replacing anything, use it for it's power to match on multiple elements
        //      in a regex and invoke a function after each match
        //   Note, the following matches on open/closed parentheses, operation ('|' or '&'), and text (e.g. course code or special instructions)
        matches.replace(/([^(^)]*?)(?:(\()|(\))|(\|)|(&)|$)/gi, (_, match, open, close, orOp, andOp) => {

            // Matched on text, check if it is a course code.  If not just store the text
            const trimmedMatch = match?.trim();
            if(trimmedMatch && trimmedMatch.length > 0) {
                const courseEntry = courseCatalog.findCourse(trimmedMatch);
                if(courseEntry) {
                    currSet.elements.push(courseEntry);
                }
                // NOTE: currently ignoring anything that is not a course entry
                // } else {
                //     currSet.elements.push(trimmedMatch);
                // }
            }
            // Matched on OR operation
            if(orOp) {
                currSet.setOperation(EquivalenceSetOp.OR);
            }
            // Matched on AND operation
            if(andOp) {
                currSet.setOperation(EquivalenceSetOp.AND);
            }
            // Matched on open parenthesis
            if(open) {
                const newSet = new EquivalenceSet();
                newSet.parent = currSet;
                currSet.elements.push(newSet);
                currSet = newSet;
            }
            // Matched on closed parenthesis
            if(close) {
                // Default operation to AND
                if(!currSet.op) {
                    currSet.setOperation(EquivalenceSetOp.AND);
                }
                currSet = currSet.parent;
            }
        });
        // Default operation to AND
        if(!thisSet.op) {
            thisSet.setOperation(EquivalenceSetOp.AND);
        }
        return thisSet;
    }
    return undefined;
}

const buildOfferedTerms = (offeringsData) => {
    // Process the offerings file to find when courses are typically offered
    const offeringTerms = new Map();
    const offeringsLines = parseLines(offeringsData, ',');
    offeringsLines.forEach((offeringEntry) => {
        const terms = array_unique(              // Find unique terms
            offeringEntry.slice(1)               // First column is course code
                .flatMap(entry => entry.split(''))   // Multiple terms may be listed in one string
                .filter(entry => entry.length > 0)   // Remove empty terms
        );
        offeringTerms.set(offeringEntry[0], terms);
    });
    return offeringTerms;
}
const termNumbersToCodes = ((offeringTerms, courseEntry) => {
    if (offeringTerms.has(courseEntry.getCourseCode())) {
        if (courseEntry.isQuarterCourse()) {
            return offeringTerms.get(courseEntry.getCourseCode()).map(entry => (
                entry === '1' ? 'Q1' :
                entry === '2' ? 'Q2' :
                entry === '3' ? 'Q3' :
                entry
            ));
        }
        if (courseEntry.isSemesterCourse()) {
            return offeringTerms.get(courseEntry.getCourseCode()).map(entry => (
                entry === '1' ? 'S1' :
                entry === '2' ? 'S2' :
                entry
            ));
        }
    }
    return [];
});

const buildOfferingHistory = (offeredData) => {
    // Process the offered file to find when courses are historically offered
    const offeringsHistory = new Map();
    const offeringsLines = parseLines(offeredData, ',');
    offeringsLines.forEach((offeringEntry) => {
        if(offeringEntry[0].length !== 6) {
            throw new Error(`Format for offered.csv year/term: ${offeringEntry[0]}`);
        }
        const year = parseInt(offeringEntry[0].substring(0, 4));
        const term = offeringEntry[0].substring(4, 6);
        const courses = offeringEntry.slice(1);
        courses.forEach(course => {
            if(!offeringsHistory.has(course)) {
                offeringsHistory.set(course, new Map());
            }
            if(!offeringsHistory.get(course).has(year)) {
                offeringsHistory.get(course).set(year, []);
            }
            offeringsHistory.get(course).set(year, offeringsHistory.get(course).get(year).concat(term));
        });
    });
    return offeringsHistory;
};

/**
 * Reads and populates the course catalog object
 * @returns {Promise<CourseCatalog>} Course catalog with all quarter and semester courses included
 */
export const importCourses = async () => {

    const [courseData, offeringsData, offeredData] = await Promise.all([
        loadFile({file: 'courseInfo.txt'}),
        loadFile({file: 'offerings.csv'}),
        loadFile({file: 'offered.csv'})
    ]);
    if(courseData === undefined) {
        throw new Error('Unable to load course catalog');
    }
    if(offeringsData === undefined) {
        throw new Error('Unable to load course offerings');
    }

    const offeringTerms = buildOfferedTerms(offeringsData);
    const offeringHistory = buildOfferingHistory(offeredData);

    const dataLines = parseLines(courseData, '\t');
    const catalog = new CourseCatalog();
    // Parse data and add new CatalogCourses
    dataLines.forEach((courseEntry) => {
        const prefix = courseEntry[0];
        const number = courseEntry[1];
        const courseName = courseEntry[2];
        const lectureHours = parseInt(courseEntry[3]);
        const labHours = parseInt(courseEntry[4]);
        const credits = isNaN(parseFloat(courseEntry[5])) ? 3 : parseFloat(courseEntry[5]);
        const repeatable = courseEntry[6] === 'Y';

        const course = new CatalogCourse({
            prefix,
            number,
            courseName,
            lectureHours,
            labHours,
            credits,
            repeatable,
        });

        catalog.addCourse(course);
    });

    // Parse lines and add dependencies and offerings
    dataLines.forEach((courseEntry) => {
        const prefix = courseEntry[0];
        const number = courseEntry[1];
        const courseCode = `${prefix}-${number}`;
        const catCourse = catalog.findCourse(courseCode);
        try {
            if (catCourse !== undefined) {
                catCourse.prereqs = buildLogicGroups(courseEntry[7], catalog);
                catCourse.coreqs = buildLogicGroups(courseEntry[8], catalog);
                catCourse.sufficients = buildLogicGroups(courseEntry[9], catalog);
                catCourse.trackOffered = termNumbersToCodes(offeringTerms, catCourse);
                if(offeringHistory.has(catCourse.getCourseCode())) {
                    catCourse.historyOffered = offeringHistory.get(catCourse.getCourseCode());
                }
            }
        } catch (error) {
            console.log(courseCode);
            console.log(error);
        }
    });

    return catalog;
}
