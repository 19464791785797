import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner} from '@fortawesome/free-solid-svg-icons';

const StyledLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: ${props => props.$loading ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  z-index: 999999 !important;
  opacity: .65;
  background-color: gray;
`;

const PageLoader = ({loading}) => (
    <StyledLoader $loading={loading}>
        <FontAwesomeIcon
            style={{
                height: '50px',
                width: '50px',
            }}
            icon={faSpinner}
            spin
        />
    </StyledLoader>
);

PageLoader.defaultProps = {
    loading: false,
}

PageLoader.propTypes = {
    loading: PropTypes.bool,
}

export default PageLoader;
