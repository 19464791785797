import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import {getCommentLines, parseLines} from './FileIO';
import CourseHistory from '../model/CourseHistory';
import Student from '../model/Student';
import {
    MajorsByName,
    MinorByName,
    pdfHeadingToYearAndTerm,
    LowestEnrollmentYear,
    gradeToStatus,
    ConcentrationByName, MajorsByCode, SuccessfulButNoCredit, SpecialYears
} from '../model/Dictionaries';
import HistoryCourse from '../model/HistoryCourse';
import CatalogCourse from "../model/CatalogCourse";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const parseCourseNumber = (courseString) => {
    const courseNumberEntries = courseString
        .split(' ').map(entry => entry.trim())
        .filter(entry => entry.length > 0);
    return { prefix: courseNumberEntries[0], number: courseNumberEntries.length > 1 ? courseNumberEntries[1] : '' };
}

/**
 * Processes a course history file or an ITP file
 * @param data Contents of the course history file or ITP file
 * @param catalog Populated course catalog
 * @returns {Promise<{student: Student, history: CourseHistory}|*|{student: Student, history: CourseHistory}>} Both a Student object and a Course history object
 */
export const importHistory = async (data, catalog) => {
    if (!data) {
        const commentLines = [];
        const history = new CourseHistory();
        const majors = Object.keys(MajorsByCode);
        const student = new Student({
            displayID: '999999',
            firstname: 'Jon',
            lastname: 'Dough',
            major: majors[majors.length * Math.random() << 0],
            startYear: 2025,
        });
        return { commentLines, history, student };
    }
    const content = new Uint8Array(data.content);
    if(!content || content.length === 0) {
        throw new Error("Unable to import file.  File contents are empty");
    }
    return data.name.slice(-4).toLowerCase() === '.pdf' ? importPdfHistory(content, catalog) : importTxtHistory(content, catalog);
}

const importTxtHistory = (data, catalog) => {
    const text = new TextDecoder().decode(data);
    const dataLines = parseLines(text, '\t');
    const commentLines = getCommentLines(text);

    // Remove column names if this is a raw export from Jenzabar
    if (dataLines[0][0] === 'id_num') {
        dataLines.shift();
    }
    var catalogYear = NaN;
    var jenzabarDate = '';
    var planDate = '';
    if (dataLines[0].length < 5) {
        const firstLine = dataLines.shift();
        if (firstLine[0].slice(0, 4) === 'STAT') {
            catalogYear = parseInt(firstLine[1]);
            planDate = firstLine[2] ? firstLine[2] : '';
            jenzabarDate = firstLine[3] ? firstLine[3] : '';
        }
    }

    // Build the course history
    const courses = [];
    dataLines.forEach((courseEntry) => {

        const buildDisplayableCourseName = (string) => {
            const { prefix, number } = parseCourseNumber(string);
            return `${prefix} ${number}`;
        }

        // Extract line values
        const year = parseInt(courseEntry[1]);
        const term = courseEntry[2];
        const courseNumber = courseEntry[3];
        const status = gradeToStatus(courseEntry[5]);
        const noCredit = SuccessfulButNoCredit.includes(courseEntry[5]);
        const credits = parseFloat(courseEntry[4]);
        const courseName = courseEntry[6];
        const assignedElective = courseEntry[21] ? buildDisplayableCourseName(courseEntry[21]) : undefined;

        const { prefix, number } = parseCourseNumber(courseNumber);

        // Search for the course in the catalog, if not found send an error
        const courseCode = `${prefix}-${number}`;
        const catCourse = catalog.findCourse(courseCode);
        const course = catCourse === undefined ?
            new CatalogCourse({
                prefix,
                number,
                courseName,
                credits
            }) : (SpecialYears.includes(year) || catCourse.credits !== credits) ?
                catCourse.deepCopyWhenDifferingCreditsOrName(courseName, credits)
                : catCourse.deepCopyWhenDifferingName(courseName, credits);

        // Add the course to the history
        courses.push(new HistoryCourse({
            year,
            term,
            course,
            status,
            noCredit,
            assignedElective,
        }))
    });
    const history = new CourseHistory({courses});

    // Extract student information
    const studentLine = dataLines[0];

    // Extract line values
    const displayID = studentLine[0];
    const lastname = studentLine[7];
    const firstname = studentLine[8];
    const major = studentLine[9];
    const classStatus = studentLine[10];
    const email = studentLine[11];
    const expectedGradDate = studentLine[12] === '' ? null : new Date(studentLine[12]);
    const minors = studentLine.slice(13, 17).filter(minor => minor.length > 0);
    const advisors = studentLine.slice(17, 20).filter(advisor => advisor.length > 0 && !advisor.includes('**'));
    const concentration = studentLine[20];
    const startYear = dataLines.map(entry => parseInt(entry[1])).filter(entry => entry >= LowestEnrollmentYear).sort()[0];
    const totalCredits = dataLines.reduce((accum, value) => (accum + parseFloat(value[4].trim())), 0);

    const studentData = {
        displayID,
        firstname,
        lastname,
        major,
        classStatus,
        expectedGradDate,
        jenzabarDate,
        planDate,
        email,
        minors,
        advisors,
        concentration,
        startYear,
        totalCredits
    };
    if (!isNaN(catalogYear)) {
        studentData['catalogYear'] = catalogYear;
    }
    // Create a new student
    const student = new Student(studentData);

    return { commentLines, history, student };
}

const extractStudentNameFromPdfText = (pdfText) => {
    if (pdfText.indexOf('NAME: ') !== -1) {
        const pdfNameStart = pdfText.slice(pdfText.indexOf('NAME: ') + 6);
        return pdfNameStart.slice(0, pdfNameStart.indexOf('\n'));
    } else {
        throw new Error('Cannot import transcript: Transcript is missing a student name.');
    }
}

const extractStudentMajorFromPdfText = (pdfText) => {
    const bsMajors = extractArrayFromPdfText(pdfText, 'BS in ');
    const bbaMajors = extractArrayFromPdfText(pdfText, 'BBA in ');
    if (bsMajors.length + bbaMajors.length === 0) {
        throw new Error('Cannot import transcript: Transcript is missing a student major.');
    }
    return MajorsByName[[...bsMajors, ...bbaMajors].sort().join('/')];
}

const extractArrayFromPdfText = (pdfText, prefix) => (
    Array.from(new Set(pdfText.split('\n').filter(line => line.startsWith(prefix)).map(line => line.slice(prefix.length))))
)

const extractStudentConcentrationFromPdfText = (pdfText) => {
    const prefix = 'Concentration in ';
    const index = pdfText.indexOf(prefix);
    return index === -1 ? '' : ConcentrationByName[pdfText.substring(index, pdfText.indexOf('\n', index)).trim()];
}

const extractStudentMinorsFromPdfText = (pdfText) => {
    const prefix = 'Minor in ';
    return Array.from(new Set(pdfText.split('\n')
        .filter(line => line.startsWith(prefix))
        .map(line => MinorByName[line.slice(prefix.length)])));
}

const extractStudentIdFromPdfText = (pdfText) => {
    const prefix = 'ID : ';
    const index = pdfText.indexOf(prefix);
    if(index === -1){
        throw new Error('Cannot import transcript: Transcript is missing a student ID.');
    }
    return pdfText.substring(index + prefix.length, index + prefix.length + 6);
}

const extractStudentTotalCreditHoursFromPdfText = (pdfText) => {
    const prefix = 'Total Credits Earned :\n \n';
    const index = pdfText.lastIndexOf(prefix);
    return index === -1 ? 0 : parseFloat(pdfText.substring(index, pdfText.indexOf('\n', index)));
}

const extractCourse = (year, term, line, catalog, courses) => {
    const entry = line.split('\t');
    const index1 = entry[0].indexOf(' ');
    const index2 = entry[0].indexOf(' ', index1 + 1);
    const prefix = entry[0].substring(0, index1).replaceAll(' ', '');
    const number = entry[0].substring(index1 + 1, index2).replaceAll(' ', '');
    const courseName = entry[0].substring(index2).trim();
    const credits = parseFloat(entry[1].trim());
    const grade = entry[2].trim();
    const pdfImportZeroCreditGrades = ['CR', 'TR', 'WV', 'WIP'];

    // Search for the course in the catalog, if not found send an error
    const courseCode = `${prefix}-${number}`;
    const catCourse = catalog.findCourse(courseCode);
    const course = catCourse === undefined ?
        new CatalogCourse({
            prefix,
            number,
            courseName,
            credits
        }) : catCourse.deepCopyWhenDifferingCreditsOrName(courseName, pdfImportZeroCreditGrades.includes(grade) ? catCourse.credits : credits);

    // Add the course to the history
    courses.push(new HistoryCourse({
        year,
        term,
        course,
        status: gradeToStatus(grade),
        noCredit: SuccessfulButNoCredit.includes(grade)
    }))

}

const extractCourseHistoryFromPdfText = (pdfText, catalog) => {
    const toIgnoreStartsWith = ['Page : ', 'Term Totals :', 'Cumulative Totals (GPA) :', 'Total Credits Earned :',
        'Organization :', 'ID : ', 'SSN : '];
    // Some course code / course title pairs were split across multiple lines.
    // Each course looks something like this:
    // SE 3910 Real-Time Systems    {CourseCode CourseTitle}
    //                              {blank}
    // 14.00                        {QualityPoints}
    // 4.00                         {CreditHours}
    // AB                           {Grade}
    //
    // The following creates a separate line for each course with {CourseCode} {CourseTitle}\t{CreditHours}\t{Grade}.
    // Term headings are in the format {AcademicYear} : {AcademicTerm} with exceptions for AP, CLEP, IB, Transfer
    // splitText contains only lines with Term headings and courses.
    const splitText = pdfText.substring(pdfText.indexOf('Undergraduate Division\n') + 23) // remove header
        .replace(/(\n[A-Z]{2} ?\d{3}[A-Z]?)(\n? *\n)([A-Z])/g, '$1  $3') // ensure 3 digit quarter courses on one line
        .replace(/(\n[A-Z]{2,3} ?\d{4}[A-Z]?)(\n? *\n)([A-Z])/g, '$1 $3')  // ensure 4 digit quarter courses on one line
        .replace(/([A-Z]{2,3} ?\d{3}.*)(\n *\n\d*\.\d{2} *\n)(\d*\.\d{2} *)(\n)(.*)(\n)/g, '$1\t$3\t$5\n')  // merge course info onto single lines
        .replace(/([A-Z]{2,3})(\d{3}.*\n)/g, '$1 $2')  // ensure space in course code
        .replace(/([A-Z]{2,3} \d{3,4}[A-Z])([A-Z].*\n)/g, '$1 $2')  // ensure space between course code and title
        .split('\n')
        .filter(line => !toIgnoreStartsWith.some(start => line.startsWith(start))) // remove unwanted lines with a colon
        .filter(line => /[A-Z]{2,3} \d{3}/.test(line) || line.includes(' : '));    // remove everything else except term headers and courses

    const courses = [];
    let year = null;
    let term = null;
    splitText.forEach(line => {
        if (line.includes(' : ')) {
            [year, term] = pdfHeadingToYearAndTerm(line);
        } else {
            extractCourse(year, term, line, catalog, courses);
        }
    });

    return new CourseHistory({courses});
}


const importPdfHistory = (data, catalog) => {
    const docInitParams = {data: data};
    return pdfjsLib.getDocument(docInitParams).promise.then(pdf => {
        const maxPages = pdf.numPages;
        const countPromises = []; // collecting all page promises
        for (let j = 1; j <= maxPages; j++) {
            const page = pdf.getPage(j);

            countPromises.push(page.then(function(page) { // add page promise
                const textContent = page.getTextContent();
                return textContent.then(function(text){ // return content promise
                    return text.items.map(function (s) { return s.str; }).join('\n'); // value page text
                });
            }));
        }

        // Wait for all pages and join text
        return Promise.all(countPromises).then(function (texts) {
            const fullText =  texts.join('\n');

            const commentLines = [];
            const history = extractCourseHistoryFromPdfText(fullText, catalog);

            const name = extractStudentNameFromPdfText(fullText);
            const displayID = extractStudentIdFromPdfText(fullText);
            const lastname = name.slice(0, name.indexOf(','));
            const hasMiddleName = name.indexOf(' ', name.indexOf(',') + 2) > -1;
            const firstname = name.slice(name.indexOf(',') + 2, hasMiddleName ? name.lastIndexOf(' ') : name.length);
            const major = extractStudentMajorFromPdfText(fullText);
            const classStatus = null;
            const email = null;
            const expectedGradDate = null;
            const minors = extractStudentMinorsFromPdfText(fullText);
            const advisors = [];
            const concentration = extractStudentConcentrationFromPdfText(fullText);
            const startYear = history.courses.map(entry => entry.year)
                .filter(entry => entry >= LowestEnrollmentYear)
                .sort()[0];
            const totalCredits = extractStudentTotalCreditHoursFromPdfText(fullText);

            // Create a new student
            const student = new Student({
                displayID,
                firstname,
                lastname,
                major,
                classStatus,
                expectedGradDate,
                email,
                minors,
                advisors,
                concentration,
                startYear,
                totalCredits
            });

            return {commentLines, history, student};
        });
    });
}
