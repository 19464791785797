import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';

import Popover from './Popover';
import ContextMenu from './ContextMenu';
import { MinorByName } from "../model/Dictionaries";
import { stringCompare } from "../utilities";

const StyledAddMinor = styled.span`
  font-weight: bold;
  cursor: default;
  @media print {
    visibility: hidden;
  }
`;

const PopupMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--main-background-color);
`;
const PopupMenuItemTitle = styled.span`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
`

const MenuItem = ({item}) => (
    <PopupMenuItem>
        <PopupMenuItemTitle>
            {item}
        </PopupMenuItemTitle>
    </PopupMenuItem>
);

const AddMinorButton = ({student, onAddMinor}) => {

    const [menuVisible, setMenuVisible] = useState(false);
    const minors = Object.keys(MinorByName).sort(stringCompare).filter(name => !student.minors.includes(MinorByName[name])).map(name => ({
            value: MinorByName[name],
            label: <MenuItem item={name} />
    }));

    return (
        <Popover
            popoverContent="Click to add a minor"
            location="bottom"
            visibilityOverride={!menuVisible}
        >
            <ContextMenu
                options={minors}
                leftClick={true}
                rightClick={false}
                placeholder='Select a minor to add'
                onMenuOpen={() => { setMenuVisible(true); }}
                onMenuClose={() => { setMenuVisible(false); }}
                onMenuOptionSelect={(minor) => {
                    if(minor) {
                        onAddMinor(minor)
                    }
                }}
            >
                <StyledAddMinor><FontAwesomeIcon icon={faCirclePlus} />&nbsp;Add Minor or Certificate</StyledAddMinor>
            </ContextMenu>
        </Popover>
    );
}
AddMinorButton.propTypes = {
    onAddMinor: PropTypes.func.isRequired,
};

export default AddMinorButton;
