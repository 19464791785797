import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import { isNull } from '../utilities';
import {AllDragDrop} from '../model/Dictionaries';

export const DisplayGridElement = styled.div`
  grid-column-end: span ${props => props.span};
  border: 1px solid #888;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  ${props => !props.horizontal &&  `flex-direction: column;`}
  ${props => props.horizontal &&  `flex-wrap: wrap;`}
  ${props => props.heading && `
      font-weight: bold;
      text-align: center;
      align-items: center;
      display: grid;
      white-space: nowrap;
      padding-left: 20px;
      padding-right: 20px;
  `}
  @media print {
    ${props => props.printVertical && `flex-direction: column;`}
  }
`;

DisplayGridElement.propTypes = {
    span: PropTypes.number.isRequired,
    heading: PropTypes.bool,
    horizontal: PropTypes.bool,
    printVertical: PropTypes.bool,
};

DisplayGridElement.defaultProps = {
    span: 1,
    heading: false,
    horizontal: false,
};

export const DroppableGridElement = (props) => {
    const {
        year,
        term,
        onDrop,
        accept,
        ...otherProps
    } = props;

    const [{isOver}, drop] = useDrop(() => ({
        accept,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
        drop: (item) => {
            onDrop(item.id, year, term);
        },
    }));

    return (
        <DisplayGridElement
            style={{ boxShadow: isOver ? 'inset 0 0 5px white' : undefined }}
            ref={drop}
            {...otherProps}
        />
    )
};
DroppableGridElement.propTypes = {
    year: PropTypes.number,
    term: PropTypes.string,
    onDrop: PropTypes.func,
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};
DroppableGridElement.defaultProps = {
    accept: AllDragDrop,
};

const buildShrinkGridTemplates = (shrink, columns) => (
    `${shrink > 0 ? `repeat(${shrink}, 1fr)` : ''} min-content repeat(${columns-shrink-1}, 1fr)`
);
const buildStretchGridTemplates = (stretch, columns) => (
    `${stretch > 0 ? `repeat(${stretch}, 1fr)` : ''} ${columns}fr repeat(${columns-stretch-1}, 1fr)`
);
export const DisplayGrid = styled.div`
  display: grid;
  gap: 2px;
  border: 1px solid #888;
  padding: 2px;
  grid-template-columns: ${props => 
          !isNull(props.shrink) ? buildShrinkGridTemplates(props.shrink, props.columns) :
          !isNull(props.stretch) ? buildStretchGridTemplates(props.stretch, props.columns) :
          `repeat(${props.columns}, 1fr)`};
  @media print {
    ${props => props.hidePrint && `visibility: hidden;`}
  }
`;

DisplayGrid.propTypes = {
    columns: PropTypes.number.isRequired,
    className: PropTypes.string,
    shrink: PropTypes.number,
    stretch: PropTypes.number,
    hidePrint: PropTypes.bool,
}

DisplayGrid.defaultProps = {
    columns: 1,
    shrink: undefined,
    stretch: undefined,
    hidePrint: false,
}
