import React, { useCallback, useEffect, useState, createContext, useContext } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";

import HeaderNav from './components/HeaderNav'
import MainContent from './components/MainContent';
import Title from './components/Title'
import TrackTable from './components/TrackTable'
import GraduationAudit from './components/GraduationAudit'
import Signatures from './components/Signatures'
import CourseSchedulingTable from './components/CourseSchedulingTable';
import Minors from './components/Minors';
import PageLoader from './components/PageLoader';
import DisplayHeader from './components/DisplayHeader';
import HelpButton from './components/HelpButton';
import ErrorHandler from './components/ErrorHandler';

import { importCourses } from './import/ImportCourses';
import { importHistory } from './import/ImportHistory';
import { importTrack } from './import/ImportTrack';
import { importMinors } from './import/ImportMinors';
import { importCreditBuckets } from './import/ImportCreditBuckets';

import { buildCourseSchedule } from './model/CourseSchedule';
import { exportPlan } from './model/ExportPlan';
import { isRemoved, SpecialYears, Status, TRANSITION_YEAR } from './model/Dictionaries';

import {array_unique, uid} from './utilities';
import ChangeLog from "./components/ChangeLog";
import AddAdvisingNote from "./components/AdvisingNote";

export const CourseCatalogContext = createContext(null);

const TrackVersionContext = createContext(0);
const useTrackVersionContext = () => useContext(TrackVersionContext);
export { TrackVersionContext, useTrackVersionContext };
export let track = null;

const HistoryVersionContext = createContext(0);
const useHistoryVersionContext = () => useContext(HistoryVersionContext);
export { HistoryVersionContext, useHistoryVersionContext };
export let history = null;
export let commentLines = null;

function App() {

    const [loading, setLoading] = useState(true);
    const [studentLoaded, setStudentLoaded] = useState(false);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [caughtError, setCaughtError] = useState(null);

    const [courseCatalog, setCourseCatalog] = useState(null);

    const [student, setStudent] = useState(null);
    const [historyVersion, setHistoryVersion] = useState(0);
    const [trackVersion, setTrackVersion] = useState(0);
    const [minors, setMinors] = useState(null);

    const [courseSchedulingStructure, setCourseSchedulingStructure] = useState(null);

    const [topicalBuckets, setTopicalBuckets] = useState(null);
    const initialAdvisingNote = 'Replace this text with an advising note, click Add Note. Export Plan to save it.';
    const [advisingNote, setAdvisingNote] = useState(initialAdvisingNote);

    useEffect(() => {
        setLoading(true);
        importCourses()
            .then((catalog) => {
                setCourseCatalog(catalog);
                importMinors(catalog).then((importedMinors) => {
                    setMinors(importedMinors);
                    setLoading(false);
                });
            });
    }, []);

    const onUpdateComplete = () => {
        setLoading(false);
        setHistoryVersion(uid());
        setTrackVersion(uid());
        setCaughtError(null);
        forceUpdate();
    }
    const onUpdateError = (error) => {
        console.log(error);
        setLoading(false);
        setCaughtError(error);
    }

    const onCourseMove = (courseEntry, year, term) => {
        if(isRemoved(year, term) && !track.courseInTrack(courseEntry)) {
            history.removeCourse(courseEntry);
        } else {
            history.moveCourse(courseEntry, year, term, courseCatalog);
        }
        history.conflictCheckAndUpdate();
        onUpdateComplete();
    };

    const onElectiveSelect = (courseEntry, electiveEntry) => {
        history.assignElective(courseEntry, electiveEntry);
        history.conflictCheckAndUpdate();
        onUpdateComplete();
    };

    const onAddCourseSelect = (courseEntry) => {
        history.addCourse(courseEntry);
        history.conflictCheckAndUpdate();
        onUpdateComplete();
    };

    const onToggleTermScheduled = (year, term, lock) => {
        lock ? history.lockTerm(year, term) : history.unlockTerm(year, term);
        onUpdateComplete();
    };

    const onClearWIPTerm = (year, term) => {
        history.unlockTerm(year, term);
        onUpdateComplete();
    };

    const onExportPlan = () => {
        exportPlan(history, student, commentLines);
    }

    const onAddNote = () => {
        if(advisingNote !== initialAdvisingNote && advisingNote.length > 0) {
            const date = new Date().toISOString();
            commentLines.push(`> ${date} ${advisingNote}`);
            setAdvisingNote('');
        }
        onUpdateComplete();
    }

    const onDeleteNote = () => {
        const date = new Date().toISOString().substring(0, 10);
        commentLines = commentLines.filter(line => !line.startsWith(`> ${date}`))
        onUpdateComplete();
    }

    const onAddMinor = (minor) => {
        student.minors.push(minor);
        setStudent(student);
        onUpdateComplete();
    };

    const onRemoveMinor = (minor) => {
        const index = student.minors.indexOf(minor);
        if (index > -1) {
            student.minors.splice(index, 1);
            setStudent(student);
            onUpdateComplete();
        }
    };

    const onAddSummer = (year) => {
        courseSchedulingStructure.set(year, year < TRANSITION_YEAR ? ['Q1', 'Q2', 'Q3', 'Q4'] : ['S1', 'S2', 'S3']);
        onUpdateComplete();
    };

    const onAddYear = () => {
        const lastYear = [...courseSchedulingStructure].sort().slice(-1)[0][0];
        courseSchedulingStructure.set(lastYear + 1, ['S1', 'S2']);
        onUpdateComplete();
    };

    const readFiles = async (student, local_history) => {
        setAdvisingNote(initialAdvisingNote);
        const importedTrack = await importTrack(student.getMajorCode(), student.getTrackAbbreviation(), courseCatalog);
        const courseSchedule = await buildCourseSchedule(importedTrack, local_history, courseCatalog, student.planDate);
        const topicalBuckets = await importCreditBuckets(student.getMajorCode(), courseCatalog);

        const scheduleStructure = new Map();
        array_unique(courseSchedule.courses.filter(entry => entry.term !== 'RM').map((entry) => entry.year))
            .forEach((year) => {
                scheduleStructure.set(year, array_unique(courseSchedule.courses.filter((entry) => entry.year === year).map(entry => entry.term)
                    .concat(year < TRANSITION_YEAR && !SpecialYears.includes(year) ? ['Q1', 'Q2', 'Q3'] :
                        year >= TRANSITION_YEAR ? ['S1', 'S2'] : [])));
            });
        courseSchedule.conflictCheckAndUpdate();

        // Set state values
        history = courseSchedule;
        track = importedTrack;

        setStudent(student);
        setCourseSchedulingStructure(scheduleStructure);
        setTopicalBuckets(topicalBuckets);
        setStudentLoaded(true);
    };

    const onTrackYearChange = (change) => {
        const asyncChangeTrackYear = async (change) => {
            student.catalogYear += change;
            history.clearTrackAssignments();
            await readFiles(student, history);
        };
        setLoading(true);
        asyncChangeTrackYear(change)
            .then(() => { onUpdateComplete(); })
            .catch((error) => { onUpdateError(error); });
    };

    const onTrackSwitch = (major) => {
        const asyncSwitchTrack = async (major) => {
            student.setMajorAndConcentration(major);
            history.clearTrackAssignments();
            await readFiles(student, history);
        }
        setLoading(true);
        asyncSwitchTrack(major)
            .then(() => { onUpdateComplete(); })
            .catch((error) => { onUpdateError(error); });
    };

    const onClearScheduling = () => {
        const asyncClearSchedule = async () => {
            history.courses = history.courses.filter(c => c.status === Status.successful ||
                c.status === Status.wip || c.status === Status.unsuccessful);
            history.courses.forEach(c => c.elective = null);
            await readFiles(student, history);
        };
        setLoading(true);
        asyncClearSchedule()
            .then(() => { onUpdateComplete(); })
            .catch((error) => { onUpdateError(error); });
    };

    const onImport = (content) => {
        const asyncImport = async (content) => {
            const importedData = await importHistory(content, courseCatalog);
            const student = importedData.student;
            const history = importedData.history;
            commentLines = importedData.commentLines;
            await readFiles(student, history);
        };
        setLoading(true);
        asyncImport(content)
            .then(() => { onUpdateComplete(); })
            .catch((error) => { onUpdateError(error); });
    }

    return (
        <ErrorHandler caughtError={caughtError} onClose={() => setCaughtError(null)}>
            <CourseCatalogContext.Provider value={courseCatalog}>
                <PageLoader loading={loading}/>
                <HeaderNav
                    transcriptLoaded={studentLoaded}
                    handleTrackSwitch={onTrackSwitch}
                    handleImportFile={onImport}
                    handleExportPlan={onExportPlan}
                />
                <MainContent>
                    <TrackVersionContext.Provider value={trackVersion}>
                        {studentLoaded ?
                            <>
                                <Title student={student}/>
                                <HistoryVersionContext.Provider value={historyVersion}>
                                    <TrackTable track={track} history={history}
                                                handleTrackYearChange={onTrackYearChange}
                                                student={student}
                                    />
                                    <Minors history={history}
                                            student={student}
                                            minors={minors}
                                            onAddMinor={onAddMinor}
                                            onRemoveMinor={onRemoveMinor}
                                    />
                                    <br/>
                                    <DndProvider backend={HTML5Backend}>
                                        <CourseSchedulingTable
                                            structure={courseSchedulingStructure}
                                            onCourseMove={onCourseMove}
                                            onAddSelect={onAddCourseSelect}
                                            onElectiveSelect={onElectiveSelect}
                                            onAddSummer={onAddSummer}
                                            onAddYear={onAddYear}
                                            onToggleTermScheduled={onToggleTermScheduled}
                                            onClearWIPTerm={onClearWIPTerm}
                                            onClearScheduling={onClearScheduling}
                                        />
                                        <AddAdvisingNote value={advisingNote}
                                                         onKeyPress={(e) => (e.key === 'Enter' && onAddNote())}
                                                         onChange={(e) => setAdvisingNote(e.target.value)}
                                                         onAddClick={() => onAddNote()}
                                                         onDeleteClick={() => onDeleteNote()}
                                                         commentLines={commentLines} />
                                    </DndProvider>
                                    <br/>
                                    <GraduationAudit topicalBuckets={topicalBuckets} history={history} track={track}/>
                                </HistoryVersionContext.Provider>
                                <Signatures student={student}/>
                            </> :
                            <>
                                <DisplayHeader>Semester Transition Advising Tool</DisplayHeader>
                                <br />
                                <DisplayHeader><HelpButton href='https://csse.msoe.us/stat' target='_blank' rel='noreferrer'>
                                    New? Get HELP!
                                </HelpButton></DisplayHeader>
                                <br />
                            </>
                        }
                    </TrackVersionContext.Provider>
                </MainContent>
                <ChangeLog student={student} commentLines={commentLines}/>
            </CourseCatalogContext.Provider>
        </ErrorHandler>
    )
}

export default App;
