import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Comments from "./Comments";

const Changes = styled.div`
  @media print {
    visibility: hidden;
  }
`;
const Dates = styled.p`
    margin: 0;
`;

const dates = (student) => {
    return !student ? <></> :
       <>
           <Dates>Last Advising Plan update: {student.planDate}</Dates>
           <Dates>Last course history update: {student.jenzabarDate}</Dates>
       </>
}
const ChangeLog = ({student, commentLines}) => (
    <>
        <Changes>
            {dates(student)}
            <Comments commentLines={commentLines} title='Changes due to Course History Updates' token='<'/>
        </Changes>
    </>
)

ChangeLog.propTypes = {
    commentLines: PropTypes.array
}

export default ChangeLog;
