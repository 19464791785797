import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import DisplayHeader from './DisplayHeader';

Modal.setAppElement('#app');

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-25%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '30px',
        color: 'var(--main-color)',
        backgroundColor: 'var(--main-background-color)'
    },
};

export const StackDisplay = styled.div` 
  border: 1px solid;
  padding: 10px;
  background-color: #fff0f0;
  color: #ff504d;
  margin-bottom: 15px;
`;

class ErrorHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modelOpen: false,
            error: null
        };
    }

    componentDidCatch(error) {
        this.setState({
            modalOpen: true,
            error: error,
        });
    }

    static getDerivedStateFromProps(props, state) {
        return ({
            modalOpen: state.error !== null || props.caughtError !== null,
            error: state.error || props.caughtError,
        });
    }

    render() {
        const { modalOpen, error } = this.state;

        const formatRows = (string) => (
            string ?
                <>
                    {string.split("\n").map((line, index) => <div key={index}>{line}</div>)}
                </> : <></>
        );

        return (
            <>
                <Modal
                    isOpen={modalOpen}
                    style={customStyles}
                >
                    <DisplayHeader>An Unexpected Error Has Occurred:</DisplayHeader>
                    <p>
                        The following error has occurred: {error?.message}
                    </p>
                    <p>
                        Please try the operation again.
                    </p>
                    <p>
                        If the error persists please contact support for assistance providing the following details:
                    </p>
                    <StackDisplay>
                        {formatRows(error?.stack)}
                    </StackDisplay>
                    <div style={{textAlign: 'center'}}>
                        <button onClick={() => {
                            this.props.onClose();
                            this.setState({
                                modalOpen: false,
                                error: null
                            });
                        }}>
                            Close
                        </button>
                    </div>
                </Modal>
                {this.props.children}
            </>
        );
    }
}

export default ErrorHandler;
